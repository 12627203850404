enum ECommonPermissions {
  CREATE = 'create',

  UPDATE = 'update',
  PARTIAL_UPDATE = 'partialUpdate',
  PARTIAL_UPDATE_MODULE = 'partialUpdate{MODULE}',

  REMOVE = 'remove',

  FIND = 'find',
  FIND_BY_ID = 'findById',
  FIND_BY_NAME = 'findByName',
  FIND_BY_CUSTOM = 'findBy{PARAMETER}',
}

export default ECommonPermissions;
