import SecureView from "../../shared/common/SecureView";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import {Badge, ProgressBar} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import createReportsRequest from "../../core/api/requests/company/company-reports/createReportsRequest";
import {useEffect, useState} from "react";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import IReports from "../../core/interfaces/IReports";
import CompanyViewOrderEvenetsModal from "../../shared/modals/CompanyViewOrderEvenetsModal";
import IOrder from "../../core/interfaces/IOrder";
import FindByIdCompanyOrdersRequest from "../../core/api/requests/company/company-orders/findByIdCompanyOrdersRequest";
import findCompanyOrdersRequest from "../../core/api/requests/company/company-orders/findCompanyOrdersRequest";
import moment from "moment/moment";
import hasPermission from "../../core/utils/hasPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import IPermission from "../../core/interfaces/IPermission";

const permission: IPermission = {
  module: EModules.COMPANY_REPORTS,
  method: ECommonPermissions.FIND,
}

export default function Reports() {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const dispatch = useDispatch();
  const permissions = useSelector((state: RootState) => state.permissions)

  const [ cancelledOrders, setCancelledOrders ] = useState<IOrder[]>([])
  const [ deliveredOrders, setDeliveredOrders ] = useState<IOrder[]>([])

  const [ selectedOrderView, setSelectedOrderView ] = useState<IOrder | null>(null);

  const [ reports, setReports ] = useState<IReports>()

  const create = async () => {
    if (!selectedCompany.company) return;

    const response = await createReportsRequest(
      dispatch,
      selectedCompany.company,
    );

    if (response.statusCode === EHttpStatusCodes.OK && response.data) {
      setReports(response.data)
    }
  }

  const getPercentage = (
    current?: number,
    total?: number,
  ): number => {
    if (!total) return 0;
    return Math.round(((current || 0) / (total || 0)) * 100)
  }

  const getLastDeliveredOrders = async () => {
    if (!selectedCompany.company) return;

    const response = await findCompanyOrdersRequest(
      dispatch,
      {
        page: 1,
        perPage: 30,
        query: '',
        status: 'delivered',
      },
      selectedCompany.company,
    )

    if (response?.data) {
      setDeliveredOrders(response.data?.data || [])
    }
  }

  const getLastCancelledOrders = async () => {
    if (!selectedCompany.company) return;

    const response = await findCompanyOrdersRequest(
      dispatch,
      {
        page: 1,
        perPage: 30,
        query: '',
        status: 'cancelled',
      },
      selectedCompany.company,
    )

    if (response?.data) {
      setCancelledOrders(response.data?.data || [])
    }
  }

  useEffect(() => {
    create()
    getLastCancelledOrders()
    getLastDeliveredOrders()
  }, []);

  const openModalEvents = async (order: IOrder) => {
    if (!selectedCompany.company) return

    const updatedOrder = await FindByIdCompanyOrdersRequest(
      dispatch,
      { id: order.id },
      selectedCompany.company,
    );

    if (updatedOrder.statusCode === EHttpStatusCodes.OK && updatedOrder.data) {
      setSelectedOrderView(updatedOrder.data)
    }
  }

  return <SecureView>

    <CompanyViewOrderEvenetsModal
      order={selectedOrderView}
      onClose={() => setSelectedOrderView(null)}
    />

    <div className={'row'}>
      <Card className={'col-4'}>
        <CardBody title={'Pedidos Requeridos'}>
          <div className={'text-right'}>
            <h1 className={'font-light'}>
              <sup>
                {reports?.partials.orders.requested}
              </sup>
            </h1>
          </div>
          <ProgressBar
            now={reports?.partials.orders.requested}
            label={`${getPercentage(reports?.partials.orders.requested, reports?.partials.orders.total)}%`}
            min={0}
            max={reports?.partials.orders.total}
            animated
            variant={'info'}
          />
        </CardBody>
      </Card>

      <Card className={'col-4'}>
        <CardBody title={'Pedidos Entregados'}>
          <div className={'text-right'}>
            <h1 className={'font-light'}>
              <sup>
                {reports?.partials.orders.delivered}
              </sup>
            </h1>
          </div>
          <ProgressBar
            now={reports?.partials.orders.delivered}
            label={`${getPercentage(reports?.partials.orders.delivered, reports?.partials.orders.total)}%`}
            min={0}
            max={reports?.partials.orders.total}
            animated
            variant={'success'}
          />
        </CardBody>
      </Card>

      <Card className={'col-4'}>
        <CardBody title={'Pedidos Cancelados'}>
          <div className={'text-right'}>
            <h1 className={'font-light'}>
              <sup>
                {reports?.partials.orders.cancelled}
              </sup>
            </h1>
          </div>
          <ProgressBar
            now={reports?.partials.orders.cancelled}
            label={`${getPercentage(reports?.partials.orders.cancelled, reports?.partials.orders.total)}%`}
            min={0}
            max={reports?.partials.orders.total}
            animated
            variant={'danger'}
          />
        </CardBody>
      </Card>
    </div>

    <div className={'row'}>
      <Card className={'col-12'}>
        <CardBody title={'Ultimos 30 pedidos cancelados'}>
          <table className="table table-danger table-bordered m-t-30 table-hover contact-list">
            <thead>
            <tr>
              <th>Numero pedido</th>
              <th>Cant. Productos</th>
              <th>Fecha</th>
              <th>Ultima edición</th>
              <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            {cancelledOrders.map((order: IOrder) => {
              return <tr key={order.id}>
                <td>{order.orderReferenceId}</td>
                <td>{order.products?.length}</td>
                <td>{moment(order.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td>{order.updateContext?.fromUser}</td>
                <td>
                  {hasPermission(permissions, { module: EModules.COMPANY_ORDERS, method: ECommonPermissions.FIND_BY_ID }) &&
                      <button onClick={() => openModalEvents(order)} className='btn btn-info btn-sm'><i className={'ti-timer'}></i></button>
                  }
                </td>
              </tr>
            })
            }
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>

    <div className={'row'}>
      <Card className={'col-12'}>
        <CardBody title={'Ultimos 30  pedidos entregados'}>
          <table className="table table-success table-bordered m-t-30 table-hover contact-list">
            <thead>
            <tr>
              <th>Numero pedido</th>
              <th>Cant. Productos</th>
              <th>Fecha</th>
              <th>Ultima edición</th>
              <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            {deliveredOrders.map((order: IOrder) => {
              return <tr key={order.id}>
                <td>{order.orderReferenceId}</td>
                <td>{order.products?.length}</td>
                <td>{moment(order.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td>{order.updateContext?.fromUser}</td>
                <td>
                  {hasPermission(permissions, { module: EModules.COMPANY_ORDERS, method: ECommonPermissions.FIND_BY_ID }) &&
                      <button onClick={() => openModalEvents(order)} className='btn btn-info btn-sm'><i className={'ti-timer'}></i></button>
                  }
                </td>
              </tr>
            })
            }
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
  </SecureView>
}
