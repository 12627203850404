import {useEffect, useState} from "react";
import {IAssignCompanyUserRequest} from "../../core/api/requests/company/company-users/assignCompanyUserRequest";
import {Button, Form, Modal} from "react-bootstrap";
import IRole from "../../core/interfaces/IRole";
import findCompanyRolesRequest from "../../core/api/requests/company/company-roles/findCompanyRolesRequest";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import {
  ICompanyUserUpdateRoleRequest
} from "../../core/api/requests/company/company-users/companyUserUpdateRoleRequest";
import IModule from "../../core/interfaces/IModule";

export interface ICompanyCreateUserModal {
  onSubmit(data: IAssignCompanyUserRequest): void;
  onSubmitEdit(data: ICompanyUserUpdateRoleRequest): void;
  onClose(): void;
  show: boolean;

  id?: string;
  email?: string;
  roleId?: string;

  module: IModule;
}

export default function (data: ICompanyCreateUserModal) {
  const dispatch = useDispatch()
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)

  const [ email, setEmail ] = useState<string>('')
  const [ roleId, setRoleId ] = useState<string>('')

  const [ roles, setRoles ] = useState<IRole[]>([]);

  const findCompanyRoles = async () => {
    if (!selectedCompany.company) return;

    const response = await findCompanyRolesRequest(
      dispatch,
      {
        query: '',
        page: 1,
        perPage: 100,
      },
      selectedCompany.company,
    )

    if (response.statusCode === EHttpStatusCodes.OK && response.data) {
      setRoles(response.data.data);
    }
  }

  const send = () => {
    if (data.id) {
      data.onSubmitEdit({
        roleId,
      })
    } else {
      data.onSubmit({
        account: email,
        roleId,
      });
    }

    data.onClose();
  }

  useEffect(() => {
    findCompanyRoles();

    if (data.roleId) setRoleId(data.roleId)

    if (data.email) setEmail(data.email)

    return () => {
      setEmail('')
      setRoleId('')
    }
  }, [data.show])

  return <Modal show={data.show} onHide={data.onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Vincular nuevo {data.module.singularName.toLowerCase()}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            disabled={!!data?.id}
            placeholder="name@example.com"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Rol funcional</Form.Label>
          <select className={'custom-select'} value={roleId} onChange={(e) => setRoleId(e.target.value)}>
            <option>Selecciona...</option>
            {roles.map(r => {
              return <option key={r.id} value={r.id}>{r.name}</option>
            })
            }
          </select>
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cancelar
      </Button>
      <Button
        variant="success"
        onClick={send}
      >
        Vincular
      </Button>
    </Modal.Footer>
  </Modal>

}
