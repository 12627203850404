export default {
  find: 'buscar',
  create: 'crear',
  remove: 'eliminar',
  findById: 'buscar por ID',
  update: 'actualizar',
  partialUpdate: 'actualización parcial',
  'productUnit.getSummary': 'ver lista stock',
  'productUnit.create': 'crear stock',
  'productUnit.find': 'buscar unidad de stock',
  'transfers.find': 'buscar transferencias',
  'transfers.create': 'crear transferencias',
  'transfers.findById': 'buscar transferencias por ID',
  'productUnit.findById': 'buscar unidades de stock por ID',
  'transfers.addProduct': 'agregar productos a transferencias',
  'transfers.updateStatus': 'despachar transferencias',
  'transfers.changeProductStatus': 'recibir transferencias',
  'transfers.removeProduct': 'eliminar productos de transferencias',
  'company.departments': 'Locales y Depósitos',
  'company.users': 'Usuarios',
  'company.products': 'Productos',
  'company.stock': 'Stock',
  'company.roles': 'Roles',
  'stock.creator': 'creador de stock',
}
