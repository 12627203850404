import {Dispatch} from "redux";
import IBasicFilters from "../../../../interfaces/IBasicFilters";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";
import IStockProduct from "../../../../interfaces/IStockProduct";

export default async function (
  dispatch: Dispatch,
  filters: any,
  company: IReducedCompany,
): Promise<IResponse<IStockProduct[]>> {
  dispatch(startLoading());

  const request = new RACARequest('companyStock', 'getSummary')
    .setQueryParameters(filters).setPathValues('COMPANY_ID', company.id)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
