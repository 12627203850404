import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import {IResponse, RACARequest} from "../../Request";
import IBasicFilters from "../../../interfaces/IBasicFilters";
import requestMiddlewares from "../../requestMiddlewares";
import IPermission from "../../../interfaces/IPermission";

export default async function (
  dispatch: Dispatch,
  filters?: IBasicFilters,
): Promise<IResponse<IPermission[]>> {
  dispatch(startLoading());

  const request = new RACARequest('roles', 'permissions')
    .setQueryParameters(filters);

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
