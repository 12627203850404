import SecureView from "../../../shared/common/SecureView";
import IModule from "../../../core/interfaces/IModule";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import {useEffect, useState} from "react";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import {useNavigate, useParams} from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes";
import ITransference from "../../../core/interfaces/ITransference";
import getCompanyStockTransferRequest
  from "../../../core/api/requests/company/company-stock/getCompanyStockTransferRequest";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import moment from "moment";
import CompanyTransferAddProductModal from "../../../shared/modals/CompanyTransferAddProductModal";
import addProductTransferenceRequest
  from "../../../core/api/requests/company/company-stock/addProductTransferenceRequest";
import IProductUnit from "../../../core/interfaces/IProductUnit";
import RCAlerts from "../../../core/utils/swal/RCAlerts";
import EHttpStatusCode from "../../../core/enums/EHttpStatusCodes";
import getMyCompanyProfileRequest from "../../../core/api/requests/me/getMyCompanyProfileRequest";
import hasPermission from "../../../core/utils/hasPermission";
import changeTransferStatusRequest from "../../../core/api/requests/company/company-stock/changeTransferStatusRequest";
import CompanyTransferReceiveProductModal from "../../../shared/modals/CompanyTransferReceiveProductModal";
import changeTransferProductStatusRequest
  from "../../../core/api/requests/company/company-stock/changeTransferProductStatusRequest";
import useQRScanner from "../../../shared/common/useQRScanner";
import ScannerForModals from "../../../shared/common/ScannerForModals";
import removeTransferProductRequest
  from "../../../core/api/requests/company/company-stock/removeTransferProductRequest";

const m: IModule = {
  module: EModules.COMPANY_STOCK,
  method: ECommonPermissions.CREATE,
  name: 'Stock',
  singularName: 'Stock',
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const [ module, setModule ] = useState<IModule>(m)
  const navigate = useNavigate();
  const params = useParams()

  const [ showAddModal, setShowAddModal ] = useState<boolean>(false)
  const [ showReceiveModal, setShowReceiveModal ] = useState<boolean>(false)

  const [ transference, setTransference ] = useState<ITransference>()

  const useQR = useQRScanner()

  const getTransference = async (id: string) => {
    let company: any = selectedCompany.company
    if (!company && params.cid) {
      company = await getMyCompanyProfileRequest(dispatch, params.cid);
    }

    if (!company) {
      return;
    }

    const response = await getCompanyStockTransferRequest(
      dispatch,
      id,
      company,
    )

    if (response.data) {
      setTransference(response.data)

      if (response.data.status === 'closed') {
        setShowReceiveModal(false)
        setShowAddModal(false)
      }
    }
  }

  const changeTransferenceStatus = async () => {
    if (!selectedCompany.company || !transference?.id) return
    if (transference.status !== 'created') return

    await changeTransferStatusRequest(
      dispatch,
      {
        status: 'inTransit',
      },
      selectedCompany.company,
      transference.id,
    )

    await getTransference(transference.id)
  }

  const addProduct = async (product: IProductUnit) => {
    if (!selectedCompany.company || !transference?.id || !product?.product?.id || !product?.id) return

    const response = await addProductTransferenceRequest(
      dispatch,
      {
        productUnitId: product.id,
        productId: product.product?.id,
      },
      selectedCompany.company,
      transference.id,
    )

    const a = new RCAlerts(module)
    if (response.statusCode !== EHttpStatusCode.ACCEPTED) {
      a.alert(
        'error',
        'Error',
        response.data.message || 'Error al agregar el producto'
      )
    }

    await getTransference(transference.id)
  }

  const removeProduct = async (id: string) => {
    if (!transference?.id || !selectedCompany.company || !id) return;

    if (transference.status !== 'created') return;

    const response = await removeTransferProductRequest(
      dispatch,
      selectedCompany.company,
      transference.id,
      id,
    )

    const a = new RCAlerts(module)
    a.delete({
      response,
      successHttpCode: EHttpStatusCode.ACCEPTED,
    })

    await getTransference(transference.id)
  }

  const changeProductStatus = async (product: IProductUnit, subDepartmentId: string) => {
    if (!transference?.id || !selectedCompany.company) return;

    if (transference.status !== 'inTransit') return;

    if (transference.from.subDepartment.id === subDepartmentId) return

    const response = await changeTransferProductStatusRequest(
      dispatch,
      {
        status: 'available',
        subDepartmentId,
      },
      selectedCompany.company,
      transference.id,
      product.id,
    )

    await getTransference(transference.id)
  }

  const goBack = () => {
    navigate(ERoutes.COMPANY_STOCK_VIEW.replaceAll(':cid', selectedCompany?.company?.id || ''))
  }

  const addProductModal = () => {
    setShowAddModal(true)
  }

  const sendTransference = () => {
    const a = new RCAlerts(module)

    a.preUpdate({
      text: 'Estas seguro que deseas despachar la transferencia?. Esta acción no puede deshacerse y ya no podrás realizar cambios.',
      onCancel() {
      },
      async onAccept() {
        changeTransferenceStatus()
      }
    })
  }

  const receiveTransference = () => {
    setShowReceiveModal(true)
  }

  useEffect(() => {
    if (params.id) {
      getTransference(params.id)
    }
  }, []);

  return <SecureView module={module} setModule={setModule}>
    {transference?.status === 'created' &&
      <CompanyTransferAddProductModal
        show={showAddModal}
        onClose={() => {
          setShowAddModal(false)
        }}
        onSubmit={addProduct}
        module={module}
        scanner={useQR}
      />
    }

    {transference?.status === 'inTransit' &&
      <CompanyTransferReceiveProductModal
        show={showReceiveModal}
        department={transference?.to.department}
        subdepartmentId={transference?.to.subDepartment?.id}
        onClose={() => {
          setShowReceiveModal(false)
        }}
        onSubmit={changeProductStatus}
        module={module}
        scanner={useQR}
      />
    }


    <Card className={'col-12'}>
      <CardBody title={`Transferencia`} autoQR>
        <Row>
          <Form.Group className="col-6">
            <Form.Label>ID</Form.Label>
            <Form.Control
              disabled
              type="text"
              value={transference?.id}
            />
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label>Fecha</Form.Label>
            <Form.Control
              disabled
              type="text"
              value={moment(transference?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            />
          </Form.Group>
          <Form.Group className="col-6 mt-3">
            <Form.Label>Origen</Form.Label>
            <Form.Control
              disabled
              type="text"
              value={`${transference?.from.department.name} - ${transference?.from.subDepartment?.name || ''}`}
            />
          </Form.Group>
          <Form.Group className="col-6 mt-3">
            <Form.Label>Destino</Form.Label>
            <Form.Control
              disabled
              type="text"
              value={`${transference?.to.department.name} - ${transference?.to?.subDepartment?.name || 'No definido'}`}
            />
          </Form.Group>
          <Form.Group className="col-3 mt-3">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              disabled
              type="text"
              value={transference?.status}
            />
          </Form.Group>
          <Form.Group className="col-3 mt-3">
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              disabled
              type="text"
              value={transference?.type}
            />
          </Form.Group>
          <Form.Group className="col-3 mt-3">
            <Form.Label>Ultima modificación</Form.Label>
            <Form.Control
              disabled
              type="text"
              value={transference?.updateContext.fromUser}
            />
          </Form.Group>
          <Form.Group className="col-3 mt-3">
            <Form.Label>Ultima modificación</Form.Label>
            <Form.Control
              disabled
              type="text"
              value={moment(transference?.updateContext.date).format('DD/MM/YYYY HH:mm:ss')}
            />
          </Form.Group>
        </Row>

        {transference?.status === 'created' &&
          <Button variant="success" type="button" onClick={sendTransference} className={'mt-5'}>
            <i className='ti-truck'></i> Despachar
          </Button>
        }

        {transference?.status === 'inTransit' &&
          <Button variant="info" type="button" onClick={receiveTransference} className={'mt-5'}>
            <i className='ti-truck'></i> Recibir
          </Button>
        }
      </CardBody>
    </Card>

    <Card className={'col-12'}>
      <CardBody>
        <Row>
          {/*{transference?.status !== 'closed' &&*/}
          {/*  <Col sm={9}>*/}
          {/*    <ScannerForModals scanner={useQR} />*/}
          {/*  </Col>*/}
          {/*}*/}

          {transference?.status === 'created' &&
          <Col sm={12}>
            {/*{hasPermission(permissions, { module: EModules.COMPANY_PRODUCTS, method: ECommonPermissions.CREATE }) &&*/}
              <Button
                type={'button'}
                onClick={addProductModal}
                className="btn btn-info"
              >Nuevo Producto
              </Button>

            {/*}*/}
          </Col>
          }

        </Row>

        <div className="table-responsive">
          <table className="table table-bordered m-t-30 table-hover contact-list">
            <thead>
            <tr>
              <th>ID</th>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Stock</th>
              {transference?.status !== 'closed' &&
                <th>Acciones</th>
              }
            </tr>
            </thead>
            <tbody>
            {transference?.products.map((products) => {
              return <tr key={products.id}>
                <td>{products.id}</td>
                <td>{products.product.name}</td>
                <td>{products.amount} {products.measurementUnit}</td>
                <td><Badge>{products.status}</Badge></td>
                {transference?.status !== 'closed' &&
                  <td>
                    {(transference?.status === 'created' && hasPermission(permissions, {
                        module: module.module,
                        method: ECommonPermissions.REMOVE
                      })) &&
                      <Button size={'sm'} variant={'danger'} onClick={() => removeProduct(products?.id || '')}>
                        <i className={'ti-trash'}></i>
                      </Button>
                    }
                  </td>
                }
              </tr>
            })
            }
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  </SecureView>
}
