import IColors from "../interfaces/IColors";

export default {
  light: {
    primary: '#000',
    secondary: '#fff',
    third: '#f0f',
    alternative: '#aaa',
    subalternative: '#eee',
  } as IColors
}
