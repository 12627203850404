import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";
import {getSession} from "../../../utils/sessionStorage";
import {setUser} from "../../../store/slices/userMeSlice";
import {setPermissions} from "../../../store/slices/permissionsSlice";
import {setCompany} from "../../../store/slices/selectedCompanySlice";

export default async function getMyCompanyProfileRequest(
  dispatch: Dispatch,
  id: string,
): Promise<IResponse> {
  const session = getSession()
  if (!session?.token) return {
    statusCode: 401,
    data: {},
  }

  const request = new RACARequest('companyMe', 'myProfile')
    .setPathValues('COMPANY_ID', id);

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  if (response.data?.company) {
    dispatch(
      setCompany(response.data.company)
    )
  }

  if (response.data?.user) {
    dispatch(
      setUser(response.data.user)
    );
  }

  if (response.data?.permissions) {
    dispatch(
      setPermissions(response.data.permissions),
    );
  }

  return response.data.company;
}
