import {IResponse, RACARequest} from "../../Request";
import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import requestMiddlewares from "../../requestMiddlewares";

export interface IPasswordRecoveryVerificationRequest {
  token: string;
  password: string;
}

export default async function(
  dispatch: Dispatch,
  input: IPasswordRecoveryVerificationRequest,
): Promise<IResponse> {
  dispatch(startLoading())

  const request = new RACARequest('auth', 'password-recovery')
    .setData(input);

  const response = await request.makeRequest()
  requestMiddlewares(
    dispatch,
    response,
  )

  dispatch(endLoading())
  return response;
}
