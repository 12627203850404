import IMenuItem from "../../core/interfaces/IMenuItem";
import ERoutes from "../../core/enums/ERoutes";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import EColors from "../../core/enums/EColors";

const menuList: IMenuItem[] = [
  {
    name: 'Dashboard',
    icon: 'ti-dashboard',
    iconColor: EColors.RACA_MAIN,
    textColor: EColors.RACA_MAIN,
    forCompany: false,
    redirectTo: ERoutes.DASHBOARD,
  },
  {
    name: 'Clientes',
    icon: 'ti-user',
    iconColor: EColors.RACA_MAIN,
    textColor: EColors.RACA_MAIN,
    forCompany: false,
    redirectTo: ERoutes.USERS_LIST,
    permission: {
      module: EModules.USERS,
      method: ECommonPermissions.FIND,
    }
  },
  {
    name: 'Compañias',
    icon: 'ti-briefcase',
    forCompany: false,
    iconColor: EColors.RACA_MAIN,
    textColor: EColors.RACA_MAIN,
    redirectTo: ERoutes.COMPANIES_LIST,
    permission: {
      module: EModules.COMPANIES,
      method: ECommonPermissions.FIND,
    }
  },
  {
    name: 'Inicio',
    icon: 'ti-dashboard',
    forCompany: true,
    redirectTo: ERoutes.DASHBOARD,
  },
  {
    name: 'Usuarios',
    icon: 'icon-people',
    forCompany: true,
    redirectTo: ERoutes.COMPANY_USERS_LIST,
    permission: {
      module: EModules.COMPANY_USERS,
      method: ECommonPermissions.FIND,
    }
  },
  {
    name: 'Roles funcionales',
    icon: 'ti-lock',
    forCompany: true,
    redirectTo: ERoutes.COMPANY_ROLES_LIST,
    permission: {
      module: EModules.COMPANY_ROLES,
      method: ECommonPermissions.FIND,
    }
  },
  {
    name: 'Departamentos',
    icon: 'ti-world',
    forCompany: true,
    redirectTo: ERoutes.COMPANY_DEPARTMENTS_LIST,
    permission: {
      module: EModules.COMPANY_DEPARTMENTS,
      method: ECommonPermissions.FIND,
    }
  },
  {
    name: 'Productos',
    icon: 'ti-money',
    forCompany: true,
    redirectTo: ERoutes.COMPANY_PRODUCTS_LIST,
    permission: {
      module: EModules.COMPANY_PRODUCTS,
      method: ECommonPermissions.FIND,
    }
  },
  {
    name: 'Ordenes',
    icon: 'ti-shopping-cart',
    forCompany: true,
    redirectTo: ERoutes.COMPANY_ORDERS_LIST,
    permission: {
      module: EModules.COMPANY_ORDERS,
      method: ECommonPermissions.FIND,
    }
  },
  {
    name: 'Dispositivos',
    icon: 'ti-tablet',
    forCompany: true,
    redirectTo: ERoutes.COMPANY_DEVICES_LIST,
    permission: {
      module: EModules.COMPANY_DEVICES,
      method: ECommonPermissions.FIND,
    }
  },
  {
    name: 'Reportes',
    icon: 'ti-pie-chart',
    forCompany: true,
    redirectTo: ERoutes.COMPANY_REPORTS,
    permission: {
      module: EModules.COMPANY_REPORTS,
      method: ECommonPermissions.FIND,
    }
  },
  {
    name: 'Stock',
    icon: 'ti-truck',
    forCompany: true,
    redirectTo: ERoutes.COMPANY_STOCK_VIEW,
    permission: {
      module: EModules.COMPANY_STOCK,
    }
  }
]

export default menuList;
