import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {useEffect, useState} from "react";
import {ICompanyDeviceCreateRequest} from "../../core/api/requests/company/company-devices/companyDeviceCreateRequest";
import IDepartment from "../../core/interfaces/IDepartment";
import findCompanyDepartmentsRequest
  from "../../core/api/requests/company/company-departments/findCompanyDepartmentsRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import {Button, Form, Modal} from "react-bootstrap";

export interface ICompanyCreateDeviceModal {
  onSubmit(data: ICompanyDeviceCreateRequest): void
  onClose(): void;
  show: boolean;
}

export default function (data: ICompanyCreateDeviceModal) {
  const dispatch = useDispatch()
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)

  const [ input, setInput ] = useState<ICompanyDeviceCreateRequest>({})

  const [ departments, setDepartments ] = useState<IDepartment[]>([]);

  const findDepartments = async () => {
    if (!selectedCompany.company) return;

    const response = await findCompanyDepartmentsRequest(
      dispatch,
      {
        query: '',
        page: 1,
        perPage: 100,
      },
      selectedCompany.company,
    );

    if (response.statusCode === EHttpStatusCodes.OK && response.data) {
      setDepartments(response.data.data);
    }
  }

  const send = () => {
    if (!input) return;

    data.onSubmit({
      ...input,
      status: 'active',
      type: 'commander',
    });

    data.onClose()
  }

  useEffect(() => {
    findDepartments();
  }, [])

  const setData = (field: string, data: any) => {
    const temp = input;

    switch (field) {
      case 'departmentId': {
        temp.departmentId = data;
        break;
      }
      case 'name': {
        temp.name = data;
        break;
      }
      default:
    }

    setInput({ ...temp });
  }

  return <Modal show={data.show} onHide={data.onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Agregar dispositivo</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Tablet 01"
            autoFocus
            value={input?.name}
            onChange={(e) => setData('name', e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Departamento</Form.Label>
          <select className={'custom-select'} value={input?.departmentId} onChange={(e) => setData('departmentId', e.target.value)}>
            <option>Selecciona...</option>
            {departments.map(r => {
              return <option key={r.id} value={r.id}>{r.name}</option>
            })
            }
          </select>
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cancelar
      </Button>
      <Button
        variant="success"
        onClick={send}
      >
        Crear
      </Button>
    </Modal.Footer>
  </Modal>
}
