import SecureView from "../../../shared/common/SecureView";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import IPermission from "../../../core/interfaces/IPermission";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import IDepartment from "../../../core/interfaces/IDepartment";
import ERoutes from "../../../core/enums/ERoutes";
import {Button, Form, InputGroup, Row} from "react-bootstrap";
import {RootState} from "../../../core/store/store";
import createCompanyProductRequest, {
  ICreateCompanyProductRequest
} from "../../../core/api/requests/company/company-products/createCompanyProductRequest";
import findCompanyDepartmentsRequest
  from "../../../core/api/requests/company/company-departments/findCompanyDepartmentsRequest";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import {Simulate} from "react-dom/test-utils";
import sweetalert2 from "sweetalert2";
import IModule from "../../../core/interfaces/IModule";

const m: IModule = {
  module: EModules.COMPANY_PRODUCTS,
  method: ECommonPermissions.CREATE,
  name: 'Productos',
  singularName: 'Producto',
}

const initial: ICreateCompanyProductRequest = {
  name: '',
  description: '',
  departmentId: '',
  sku: '',
  category: '',
  stock: 0,
  additional: {},
  measurementUnit: '',
  status: 'active',
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany);
  const dispatch = useDispatch();
  const futils = useSelector((state: RootState) => state.frontendUtilities);
  const navigator = useNavigate();
  const [ module, setModule ] = useState<IModule>(m)

  const [ departments, setDepartments ] = useState<IDepartment[]>([]);

  const [ data, setData ] = useState<ICreateCompanyProductRequest>(initial)

  const goBack = () => navigator(ERoutes.COMPANY_PRODUCTS_LIST)

  const findDepartments = async () => {
    if (!selectedCompany.company) return;

    const response = await findCompanyDepartmentsRequest(
      dispatch,
      {
        query: '',
        page: 1,
        perPage: 100,
      },
      selectedCompany.company,
    );

    if (response.statusCode === EHttpStatusCodes.OK && response.data) {
      setDepartments(response.data.data);
    }
  }

  const create = async () => {
    if (!selectedCompany.company) return;

    const response = await createCompanyProductRequest(
      dispatch,
      data,
      selectedCompany.company,
    );

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Producto creado exitosamente`,
        'success',
      ).then(() => goBack())
    } else {
      sweetalert2.fire(
        `Error`,
        response.data.message,
        'error',
      )
    }
  }

  const setValue = (field: string, value: string) => {
    const temp = data;

    switch (field) {
      case 'sku': {
        temp.sku = value;
        break;
      }
      case 'name': {
        temp.name = value;
        break;
      }
      case 'description': {
        temp.description = value;
        break;
      }
      case 'price.amount': {
        // temp?.price?.amount = Number(value);
        break;
      }
      case 'departmentId': {
        temp.departmentId = value;
        break;
      }
      case 'measurementUnit': {
        temp.measurementUnit = value;
        break;
      }
      case 'category': {
        temp.category = value;
        break;
      }
      case 'status': {
        temp.status = value;
        break;
      }
      case 'stock': {
        temp.stock = Number(value);
        break;
      }
      default:
    }

    setData({ ...temp })
  }

  useEffect(() => {
    findDepartments();
  }, [])

  useEffect(() => {
    if (module?.private?.defaultCurrency) {
      setData({
        ...data,
        price: {
          ...data.price,
          currency: module.private.defaultCurrency,
        }
      })
    }
  }, [module]);

  return <SecureView module={module} setModule={setModule}>
    <Card className={'col-12'}>
      <CardBody title={`Nuevo ${module.singularName}`}>
        <Form>
          <Row className={'mb-4'}>
            <Form.Group className="col-4">
              <Form.Label>SKU</Form.Label>
              <Form.Control
                type="text"
                placeholder="Código SKU"
                value={data.sku}
                onChange={(e) => setValue('sku', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="col-4">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Pollo x kg"
                value={data.name}
                onChange={(e) => setValue('name', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="col-4">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={data.description}
                onChange={(e) => setValue('description', e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className={'mb-4'}>
            <Form.Group className="col-4">
              <Form.Label>Departamento</Form.Label>
              <select className={'custom-select'} value={data.departmentId} onChange={(e) => setValue('departmentId', e.target.value)}>
                <option value={'-1'}>Selecciona...</option>
                {departments.map((dpt) => <option key={dpt.id} value={dpt.id}>{dpt.name}</option>)
                }
              </select>
            </Form.Group>

            <Form.Group className="col-4">
              <Form.Label>Unidad de medida</Form.Label>
              <select className={'custom-select'} value={data.measurementUnit} onChange={(e) => setValue('measurementUnit', e.target.value)}>
                <option value={'-1'}>Selecciona...</option>
                {futils?.futils?.measurementUnits.map((dpt) => <option key={dpt} value={dpt}>{dpt}</option>)
                }
              </select>
            </Form.Group>

            <Form.Group className="col-4">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={data.category}
                onChange={(e) => setValue('category', e.target.value)}
              />
            </Form.Group>
          </Row>

          <Button variant="info" type="button" onClick={create} className={'mt-3'}>
            <i className='ti-save'></i> Crear
          </Button>

          <Button variant="secondary" type="button" onClick={() => goBack()} className={'mt-3 ml-2'}>
            Cancelar
          </Button>
        </Form>
      </CardBody>
    </Card>
  </SecureView>
}
