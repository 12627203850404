import SecureView from "../../../shared/common/SecureView";
import IModule from "../../../core/interfaces/IModule";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import {useEffect, useState} from "react";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import {useNavigate} from "react-router-dom";
import IProduct from "../../../core/interfaces/IProduct";
import findCompanyProductsRequest from "../../../core/api/requests/company/company-products/findCompanyProductsRequest";
import listProductTransfersRequest from "../../../core/api/requests/company/company-stock/listProductTransfersRequest";
import ERoutes from "../../../core/enums/ERoutes";
import ITransference from "../../../core/interfaces/ITransference";
import hasPermission from "../../../core/utils/hasPermission";
import {Badge, Button} from "react-bootstrap";
import Paginator from "../../../shared/common/Paginator";
import moment from "moment";

const m: IModule = {
  module: EModules.COMPANY_STOCK,
  method: ECommonPermissions.CREATE,
  name: 'Stock',
  singularName: 'Stock',
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const [ module, setModule ] = useState<IModule>(m)

  const navigate = useNavigate();

  const [ data, setData ] = useState<ITransference[]>([])

  const [ count, setCount ] = useState<number>(0);
  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');

  const changePageAndFind = (page: number) => find(page)

  const find = async (
    pageByParam?: number,
  ) => {
    if (!selectedCompany.company) return;

    if (pageByParam) setPage(pageByParam)

    const response = await listProductTransfersRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
      selectedCompany.company,
    )

    if (response?.data) {
      setData(response.data?.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)
    }
  }

  const goBack = () => {
    navigate(ERoutes.COMPANY_STOCK_VIEW.replace(':cid', selectedCompany?.company?.id || ''))
    return

  }

  const redirectToTransfer = (id: string) => {
    navigate(ERoutes.COMPANY_STOCK_TRANSFERENCES_VIEW
      .replaceAll(':cid', selectedCompany?.company?.id || '')
      .replaceAll(':id', id || ''))
    return
  }

  useEffect(() => {
    find()
  }, [selectedCompany.company])

  useEffect(() => {
    setPage(1)
  }, [query])

  return <SecureView module={module} setModule={setModule}>
    <Card className={'col-12'}>
      <CardBody title={`transferencias (${count})`}>
        <div className="table-responsive">
          <table className="table table-bordered m-t-30 table-hover contact-list">
            <thead>
            <tr>
              <th>ID</th>
              <th>Origen</th>
              <th>Destino</th>
              <th>Estado</th>
              <th>Tipo</th>
              <th>Productos</th>
              <th>Fecha</th>
              <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            {data.map((transfer) => {
              return <tr key={transfer.id}>
                <td>{transfer.id}</td>
                <td>{transfer.from.department.name} - {transfer.from.subDepartment.name}</td>
                <td>{transfer.to.department.name} {transfer.to?.subDepartment?.name}</td>
                <td><Badge>{transfer.status}</Badge></td>
                <td><Badge>{transfer.type}</Badge></td>
                <td>{transfer.products.length}</td>
                <td>{moment(transfer.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                <td>
                  {hasPermission(permissions, {module: module.module, method: ECommonPermissions.FIND_BY_ID}) &&
                    <Button size={'sm'} variant={'secondary'} onClick={() => redirectToTransfer(transfer?.id || '')}>
                      <i className={'ti-eye'}></i> Ver
                    </Button>
                  }
                </td>
              </tr>
            })
            }
            </tbody>
          </table>

          <Paginator
            total={totalPages}
            page={page}
            goTo={changePageAndFind}
          />
        </div>
      </CardBody>
    </Card>
  </SecureView>
}
