import IPermission from "../interfaces/IPermission";

export default function hasPermission(
  permissions: IPermission[],
  requested: IPermission,
): boolean {
  if (permissions.find((p) => p.module === '*' && p.method === '*')) return true;

  if (permissions.find((p) => p.module === requested.module && p.method === '*')) return true;

  if (!requested.method) {
    if (permissions.find((p) => p.module === requested.module)) return true;
  }

  if (permissions.find((p) => p.module === requested.module && p.method === requested.method)) return true;

  return false
}
