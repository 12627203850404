import {useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import IModule from "../../core/interfaces/IModule";
import IProductUnit from "../../core/interfaces/IProductUnit";
import {setScanner} from "../../core/store/slices/scannerSlice";
import findByIdCompanyProductsRequest
  from "../../core/api/requests/company/company-products/findByIdCompanyProductsRequest";
import getCompanyStockByProduct from "../../core/api/requests/company/company-stock/getCompanyStockByProduct";
import IProduct from "../../core/interfaces/IProduct";
import moment from "moment/moment";
import Paginator from "../common/Paginator";

export interface ICompanyCreateDepartmentModal {
  onClose(): void;
  show: boolean;
  module: IModule;
  productId: string;
}

export default function (data: ICompanyCreateDepartmentModal) {
  const dispatch = useDispatch()
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)

  const [ product, setProduct ] = useState<IProduct>()
  const [ productUnit, setProductUnit ] = useState<IProductUnit[]>()

  const [ count, setCount ] = useState<number>(0);
  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');


  useEffect(() => {
    if (data.productId && data.show) {
      getProduct(data.productId)
      getStock(1, data.productId)
    }
  }, [data.show])

  const seeQR = (pu: IProductUnit) => {
    const url = pu.id

    dispatch(setScanner({
      showModal: true,
      sessionId: url,
      title: pu.product.name,
      subtitle: `${pu.amount} ${pu.measurementUnit}`
    }))
  }

  const changePageStockAndFind = (page: number) => getStock(page)

  const getStock = async (pageByParam?: number, productId?: string) => {
    if (!selectedCompany.company || (!product?.id && !productId)) return;
    if (pageByParam) setPage(pageByParam)

    const response = await getCompanyStockByProduct(
      dispatch,
      product?.id || productId || '',
      {
        page: pageByParam || page,
        perPage,
        query,
      },
      selectedCompany.company,
    )

    if (response?.data) {
      setProductUnit(response.data.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)
    }
  }

  const getProduct = async (id: string) => {
    if (!selectedCompany.company) return

    const response = await findByIdCompanyProductsRequest(
      dispatch,
      id,
      selectedCompany.company,
    )

    if (response.data) {
      setProduct(response.data)
    }
  }

  useEffect(() => {
    if (data.productId && data.show) {
      getProduct(data.productId)
      getStock(1, data.productId)
    }
  }, [data.productId]);

  return <Modal size={'xl'} show={data.show} onHide={data.onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Unidades de producto {product?.name}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="table-responsive">
        <table className="table table-bordered m-t-30 table-hover contact-list">
          <thead>
          <tr>
            <th>ID</th>
            <th>Fecha</th>
            <th>Cantidad</th>
            <th>Ultimo movimiento</th>
            <th>Movimientos</th>
            <th>Ubicación actual</th>
            <th>Acciones</th>
          </tr>
          </thead>
          <tbody>
          {productUnit?.map((pu) => {
            return <tr key={pu.id}>
              <td>{pu.id}</td>
              <td>{moment(pu.createdAt).format('DD/MM/YYYY HH:mm')}</td>
              <td>{pu.amount} {pu.measurementUnit}</td>
              <td>{pu.updateContext.fromUser} ({moment(pu.updateContext.date).format('DD/MM/YYYY HH:mm:ss')})</td>
              <td>{pu.historic.length}</td>
              <td>{pu.location.department.name} - {pu.location.subDepartment?.name}</td>
              <td>
                <Button size={'sm'} variant={'light'} onClick={() => seeQR(pu)}>
                  <i className={'mdi mdi-qrcode'}></i>
                </Button>
              </td>
            </tr>
          })
          }
          </tbody>
        </table>

        <Paginator
          total={totalPages}
          page={page}
          goTo={changePageStockAndFind}
        />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
}
