import SecureView from "../../shared/common/SecureView";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import {useDispatch} from "react-redux";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import {Button, Form, Row} from 'react-bootstrap';
import findRolesRequest from "../../core/api/requests/roles/findRolesRequest";
import {useEffect, useState} from "react";
import IRole from "../../core/interfaces/IRole";
import sweetalert2 from "sweetalert2";
import createUserRequest, {ICreateUserRequest} from "../../core/api/requests/users/createUserRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import EHttpStatusCode from "../../core/enums/EHttpStatusCodes";
import ERoutes from "../../core/enums/ERoutes";
import {useNavigate} from "react-router-dom";
import IModule from "../../core/interfaces/IModule";
import RCAlerts from "../../core/utils/swal/RCAlerts";

const m: IModule = {
  module: EModules.USERS,
  method: ECommonPermissions.CREATE,
  name: 'Clientes',
  singularName: 'Cliente',
}

export default function UserNew() {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [ module, setModule ] = useState<IModule>(m)

  const [ roles, setRoles ] = useState<IRole[]>([]);

  const [ user, setUser ] = useState<ICreateUserRequest>({});

  const goBack = () => navigator(ERoutes.USERS_LIST)

  const findRoles = async () => {
    const response = await findRolesRequest(
      dispatch,
      {
        query: '',
        page: 1,
        perPage: 100,
      },
    )

    if (response?.data?.data) {
      setRoles(response.data.data);
    }
  }

  const createUser = async () => {
    const response = await createUserRequest(
      dispatch,
      user,
    );

    const s = new RCAlerts(module)

    s.created({
      response,
      successHttpCode: EHttpStatusCode.ACCEPTED,
      onAccept() {
        goBack()
      },
    })
  }

  const setUserValue = (
    field: string,
    value: any,
  ) => {
    setUser((prevState) => {
      return {
        ...prevState,
        [field]: value,
      }
    });
  }

  useEffect(() => {
    findRoles()
  }, [])

  return <SecureView module={module} setModule={setModule} forAdmins>
    <Card className={'col-12'}>
      <CardBody title={`Nuevo ${module.singularName}`}>
        <Form>
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="John"
                value={user.name}
                onChange={(e) => setUserValue('name', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Apellido</Form.Label>
              <Form.Control
                type="text"
                placeholder="Doe"
                value={user.lastName}
                onChange={(e) => setUserValue('lastName', e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="example@racasf.eu"
                value={user.email}
                onChange={(e) => setUserValue('email', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3 col-6">
              <Form.Label>Rol</Form.Label>
                <select
                  className={'custom-select'}
                  value={user.roleId}
                  onChange={(e) => setUserValue('roleId', e.target.value)}
                >
                  <option>Seleccionar rol</option>
                  {roles.map((r) => {
                    return <option
                      key={r.id}
                      value={r.id}
                      selected={r.id === user.roleId}
                    >
                      {r.name}
                    </option>
                  })
                  }
                </select>
            </Form.Group>
          </Row>

          <Button variant="info" type="button" onClick={createUser} className={'mt-3'}>
            <i className='ti-save'></i> Crear
          </Button>

          <Button variant="secondary" type="button" onClick={() => goBack()} className={'mt-3 ml-2'}>
            Cancelar
          </Button>
        </Form>
      </CardBody>
    </Card>
  </SecureView>
}
