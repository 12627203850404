import {Dispatch} from "redux";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";

export interface ICreateCompanyProductRequest {
  value: string;
  data: any;
}

export default async function (
  dispatch: Dispatch,
  input: ICreateCompanyProductRequest,
  cid: string,
  token: string,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('companyScanner', 'sendScan')
    .setData(input).setToken(token).setPathValues("COMPANY_ID", cid)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());
  return response;
}
