import {Dispatch} from "redux";
import IBasicFilters from "../../../../interfaces/IBasicFilters";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";
import IProduct from "../../../../interfaces/IProduct";

export default async function (
  dispatch: Dispatch,
  id: string,
  company: IReducedCompany,
): Promise<IResponse<IProduct>> {
  dispatch(startLoading());

  const request = new RACARequest('companyProducts', 'view')
    .setPathValues('COMPANY_ID', company.id)
    .setPathValues('ID', id)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
