import {useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import QRCode from "react-qr-code";
import getPrinterDocument from "../../core/utils/getPrinterDocument";
import moment from "moment";
import {useNavigate} from "react-router-dom";

export interface ICompanyCreateDepartmentModal {
  onClose(): void;
  show: boolean;
}

export default function (data: ICompanyCreateDepartmentModal) {
  const dispatch = useDispatch()
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const scanner = useSelector((state: RootState) => state.scanner)

  const [ name, setName ] = useState<string>('')

  useEffect(() => {
    return () => {
      setName('')
    }
  }, [data.show])

  const print = () => {
    let url = `/printer/tag?`

    const params: any[] = [
      { key: 'h', value: '75mm' },
      { key: 'w', value: '64mm' },
      { key: 'c', value: scanner.sessionId },
      { key: 't', value: scanner.title },
      { key: 'st', value: scanner.subtitle },
      { key: 'd', value: scanner.description },
      { key: 'tt', value: moment(scanner.time || new Date()).format('DD/MM/YYYY HH:mm:ss')},
      { key: 'pv', value: '1mm' },
      { key: 'ph', value: '1mm' },
      { key: 'qrw', value: '25mm' },
      { key: 'qrh', value: '25mm' },
    ]

    params.forEach((p, index) => {
      if (!p.value) return

      url += `${encodeURIComponent(p.key)}=${encodeURIComponent(p.value)}`

      if (index < params.length-1) {
        url += '&'
      }
    })

    window.open(url)
  }

  return <Modal show={data.show} onHide={data.onClose}>
    <Modal.Body style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          {scanner.sessionId &&
            <>
              <QRCode value={scanner.sessionId} />

              <div className={'mt-2 text-center'}>
                {scanner.title &&
                  <h2>{scanner.title}</h2>
                }

                {scanner.subtitle &&
                  <h4>{scanner.subtitle}</h4>
                }

                {scanner.description &&
                  <p>{scanner.description}</p>
                }

                {scanner.code &&
                  <p>{scanner.code}</p>
                }

                {scanner.title &&
                  <p>{moment(scanner.time).format('DD/MM/YYYY HH:mm:ss')}</p>
                }
              </div>

              {/*<small style={{ marginTop: 10, textAlign: 'center' }}>{scanner.sessionId}</small>*/}
            </>
          }
    </Modal.Body>
    <Modal.Footer>
      {scanner.sessionId &&
        <Button className={'w-100'} variant="info" onClick={print}>Imprimir</Button>
      }
      <Button className={'w-100'} variant="secondary" onClick={data.onClose}>Cerrar</Button>
    </Modal.Footer>
  </Modal>

}
