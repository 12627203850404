import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IUser from "../../interfaces/IUser";
import IPermission from "../../interfaces/IPermission";
import IRole from "../../interfaces/IRole";

type SliceType = {
  user?: IUser;
  mainRole?: IRole;
}

const initialState: SliceType = {};

const slice = createSlice({
  name: EStores.USER_ME,
  initialState,
  reducers: {
    setUser(state: SliceType, data: PayloadAction<IUser>) {
      return {
        ...state,
        user: data.payload,
      }
    },
    setMainRole(state: SliceType, data: PayloadAction<IRole>) {
      return {
        ...state,
        mainRole: data.payload,
      }
    },
  },
});

export const {
  setUser,
  setMainRole,
} = slice.actions;

export default slice.reducer;
