import {Dispatch} from "redux";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";
import IOrder from "../../../../interfaces/IOrder";

export interface ICompanyOrderUpdateStatusRequest {
  order: IOrder,
  status: string;
  description: string;
}

export default async function (
  dispatch: Dispatch,
  input: ICompanyOrderUpdateStatusRequest,
  company: IReducedCompany,
): Promise<IResponse> {
  dispatch(startLoading())

  const request = new RACARequest('companyOrders', 'update')
    .setPathValues('COMPANY_ID', company.id).setPathValues('ID', input.order.id)
    .setData({
      action: 'updateStatus',
      data: {
        status: input.status,
        description: input.description,
      }
    })

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading())

  return response;
}
