import {useEffect, useState} from "react";
import {Button, Col, Form, InputGroup, Modal, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {
  ICompanyUserRemoveRequest
} from "../../core/api/requests/company/company-departments/companyDepartmentCreateRequest";
import IModule from "../../core/interfaces/IModule";
import IDepartment from "../../core/interfaces/IDepartment";
import findByIdCompanyDepartmentsRequest
  from "../../core/api/requests/company/company-departments/findByIdCompanyDepartmentsRequest";
import findCompanySubdepartmentsRequest
  from "../../core/api/requests/company/company-departments/subdepartments/findCompanySubdepartmentsRequest";
import ISubdepartment from "../../core/interfaces/ISubdepartment";
import capitalizeFirstLetter from "../../core/utils/CapitalizeFirstLetter";
import RCAlerts from "../../core/utils/swal/RCAlerts";
import companySubdepartmentCreateRequest
  from "../../core/api/requests/company/company-departments/subdepartments/companySubdepartmentCreateRequest";
import EHttpStatusCode from "../../core/enums/EHttpStatusCodes";
import companySubdepartmentUpdateRequest
  from "../../core/api/requests/company/company-departments/subdepartments/companySubdepartmentUpdateRequest";
import companySubdepartmentRemoveRequest
  from "../../core/api/requests/company/company-departments/subdepartments/companySubdepartmentRemoveRequest";
import companyDepartmentUpdateRequest
  from "../../core/api/requests/company/company-departments/companyDepartmentUpdateRequest";

export interface IInput {
  onSubmit(data: ICompanyUserRemoveRequest): void;
  onClose(): void;
  show: boolean;
  module: IModule;
  department: string;
}

export default function (data: IInput) {
  const dispatch = useDispatch()
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)

  const [ name, setName ] = useState<string>('')

  const [ department, setDepartment ] = useState<IDepartment>()

  const [ subdepartments, setSubdepartments ] = useState<ISubdepartment[]>([])

  const [ subdepartmentName, setSubdepartmentName ] = useState<string>('')

  const send = () => {
    data.onSubmit({
      name,
    });

    data.onClose();
  }

  const getSubdepartments = async (id: string) => {
    if (!selectedCompany.company) return

    const response = await findCompanySubdepartmentsRequest(
      dispatch,
      selectedCompany.company,
      id,
    )

    if (response.data) {
      setSubdepartments(response.data.data)
    }
  }

  const update = async () => {
    if (!selectedCompany.company) return

    const response = await companyDepartmentUpdateRequest(
      dispatch,
      {
        name: name,
      },
      selectedCompany.company,
      data.department,
    )

    new RCAlerts(data.module).created({
      response,
      successHttpCode: EHttpStatusCode.ACCEPTED,
      onAccept() {
        data.onClose()
      },
      onCancel() {
        getSubdepartments(data.department)
      }
    })
  }

  const get = async (id: string) => {
    if (!selectedCompany.company) return

    const response = await findByIdCompanyDepartmentsRequest(
      dispatch,
      selectedCompany.company,
      id,
    )

    if (response.data) {
      setDepartment(response.data)

      setName(response.data.name)
    }
  }

  useEffect(() => {
    if (data.department) {
      get(data.department)
      getSubdepartments(data.department)
    }

    return () => {
      setDepartment(undefined)
    }
  }, [data.show])

  const removeSubdepartment = (id?: string) => {
    if (!selectedCompany.company || !id) return

    new RCAlerts(data.module).preDelete({
      async onAccept() {
        if (!selectedCompany.company || !id) return

        const response = await companySubdepartmentRemoveRequest(
          dispatch,
          selectedCompany.company,
          data.department,
          id,
        )

        new RCAlerts(data.module).delete({
          response,
          successHttpCode: EHttpStatusCode.OK,
          onAccept() {
            getSubdepartments(data.department)
          }
        })
      },
      onCancel() {
        getSubdepartments(data.department)
      }
    })
  }

  const addSubdepartment = async () => {
    if (!selectedCompany.company) return

    const response = await companySubdepartmentCreateRequest(
      dispatch,
      {
        name: subdepartmentName,
      },
      selectedCompany.company,
      data.department,
    )

    new RCAlerts(data.module).created({
      response,
      successHttpCode: EHttpStatusCode.ACCEPTED,
      onAccept() {
        getSubdepartments(data.department)
        setSubdepartmentName('')
      },
      onCancel() {
        getSubdepartments(data.department)
      }
    })
  }

  const changeSubprojectName = (value: string, id?: string) => {
    if (!selectedCompany.company) return

    new RCAlerts(data.module).preUpdate({
      async onAccept() {
        if (!selectedCompany.company || !id) return

        const response = await companySubdepartmentUpdateRequest(
          dispatch,
          {
            name: value,
          },
          selectedCompany.company,
          data.department,
          id,
        )

        new RCAlerts(data.module).update({
          response,
          successHttpCode: EHttpStatusCode.ACCEPTED,
          onAccept() {
            getSubdepartments(data.department)
          }
        })
      },
      onCancel() {
        getSubdepartments(data.department)
      }
    })
  }


  return <Modal size={'lg'} show={data.show} onHide={data.onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Modificar {data.module.singularName}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese un nombre..."
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Row>

        </Row>

        <Row>
          <Col>
            <h6>{capitalizeFirstLetter(data.module?.private?.subdepartmentName || 'Subdepartamentos')}</h6>
          </Col>
          <Col sm={12}>
            {subdepartments.map((sd) => {
              return <InputGroup className="mb-3">
                <Form.Control
                  defaultValue={sd.name}
                  onBlur={(e) => changeSubprojectName(e.target.value, sd.id)}
                />
                <Button onClick={() => removeSubdepartment(sd.id)} variant="danger" id="button-addon2">
                  <i className={'ti-trash'}></i>
                </Button>
              </InputGroup>
            })
            }
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Ingrese un nombre..."
                value={subdepartmentName}
                onChange={(e) => setSubdepartmentName(e.target.value)}
              />
              <Button onClick={() => addSubdepartment()} variant="success" id="button-addon2">
                <i className={'ti-check'}></i>
              </Button>
            </InputGroup>
          </Col>
        </Row>

      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cancelar
      </Button>
      <Button variant="success" onClick={update}>
        Modificar
      </Button>
    </Modal.Footer>
  </Modal>

}
