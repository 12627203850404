import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import SecureRoutes from "./core/routes/SecureRoutes";
import NoAuthRoutes from "./core/routes/NoAuthRoutes";

function App() {
  return (
    <BrowserRouter>
      <NoAuthRoutes />
      <SecureRoutes />
    </BrowserRouter>
  );
}

export default App;
