import {Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";

import configuration from "../../../configuration";

export default function Loader() {
  const loading = useSelector((state: RootState) => state.loading)

  return loading ? (
      <div className={'loading-container'}>
        <div className={'spinner-container'}>
          <Spinner animation="grow" variant="white"/>
          <p>{configuration.application.name}</p>
        </div>
      </div>
  ) : <></>
}
