import IScanner from "../../core/interfaces/IScanner";
import {Badge, Button} from "react-bootstrap";
import moment from "moment/moment";

type IInput = {
  scanner: IScanner;
}

export default function (data: IInput) {
  if (!data.scanner.session) return <div>
    <Button variant="info" onClick={data.scanner.openScanner}>
      Activar Escaner
    </Button>
  </div>

  return <div className={'session-container'}>
    <div className={'badge-container'}>
      <Badge className={'bg-secondary'}>{data.scanner.session.id}</Badge>
      <Badge className={'bg-warning'}>Last
        Check: {moment(data.scanner.lastConnectionCheck).format("DD/MM/YYYY HH:mm:ss")}</Badge>
      <Badge
        className={data.scanner.connected ? 'bg-success' : 'bg-danger'}>Estado: {data.scanner.connected ? 'conectado' : 'desconectado'}</Badge>
      <Badge
        className={data.scanner.linked ? 'bg-success' : 'bg-danger'}>{data.scanner.linked ? 'linkeado' : 'no linkeado'}</Badge>
    </div>

    {!data.scanner.linked &&
      <Button variant="info" onClick={data.scanner.makeLink}>
        Linkear
      </Button>
    }

    <Button variant="info" onClick={data.scanner.seeQR}>
      Ver QR
    </Button>
  </div>
}
