import IOrder from "../../core/interfaces/IOrder";
import {useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {Badge, Button, Modal} from "react-bootstrap";
import moment from "moment";
import tlt from "../../core/api/helpers/translator/translator";

export interface ICompanyViewOrderEventsModal {
  order: IOrder | null;
  onClose(): void;
}

export default function (data: ICompanyViewOrderEventsModal) {
  return <Modal size={'xl'} show={!!data.order} onHide={data.onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Eventos de productos</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className={'container-fluid'}>
        <div className={'row'}>
          {data.order?.products.map((product) => {
            return <div className={'col-12 order-product-event'}>
              <h6>{product.product.name} - SKU: {product.product.sku}</h6>
              <p>Estado actual: <Badge className={'ml-2'}>{tlt(product.status)}</Badge></p>
              <p>Cantidad: {product.count}</p>
              <hr />
              <table className={'table table-bordered table-sm table-secondary'}>
                <thead>
                <tr>
                  <th>Evento</th>
                  <th>Horario</th>
                  <th>Descripción</th>
                  <th>Usuario</th>
                </tr>
                </thead>
                <tbody>
                {product?.events?.map((event) => {
                  return <tr>
                    <td>{tlt(event.status)}</td>
                    <td>{moment(event.updateContext.date).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{event.description}</td>
                    <td>{event.updateContext.fromUser}</td>
                  </tr>
                })
                }
                </tbody>
              </table>
            </div>
          })
          }

        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
}
