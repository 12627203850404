import IModule from "../../../core/interfaces/IModule";
import EModules from "../../../core/enums/EModules";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import {useState} from "react";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import SecureView from "../../../shared/common/SecureView";
import MenuButton from "../../../shared/common/MenuButton";
import {Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes";
import hasPermission from "../../../core/utils/hasPermission";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";

const m: IModule = {
  module: EModules.COMPANY_STOCK,
  name: 'Stock',
  singularName: 'Stock',
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const [ module, setModule ] = useState<IModule>(m)
  const navigate = useNavigate();

  const goToMakeStock = () => {
    if (!selectedCompany?.company?.id) return

    const url = ERoutes.COMPANY_STOCK_NEW
      .replaceAll(':cid', selectedCompany.company.id)
    navigate(url)
    return
  }

  const goToTransferences = () => {
    if (!selectedCompany?.company?.id) return

    const url = ERoutes.COMPANY_STOCK_TRANSFERENCES_LIST
      .replaceAll(':cid', selectedCompany.company.id)
    navigate(url)
    return
  }

  const goToCreateTransfer = () => {
    if (!selectedCompany?.company?.id) return

    const url = ERoutes.COMPANY_STOCK_TRANSFERENCES_CREATE
      .replaceAll(':cid', selectedCompany.company.id)
    navigate(url)
    return
  }

  const goToSummary = () => {
    if (!selectedCompany?.company?.id) return

    const url = ERoutes.COMPANY_STOCK_VIEW_SUMMARY
      .replaceAll(':cid', selectedCompany.company.id)
    navigate(url)
    return
  }

  return <SecureView module={module} setModule={setModule}>
    <Card className={'col-12'}>
      <CardBody>
        <Row>
          {hasPermission(permissions, { module: module.module, method: 'productUnit.create' }) &&
            <MenuButton onClick={goToMakeStock} color={'dark'}>Crear Unidad de Stock</MenuButton>
          }

          {hasPermission(permissions, { module: module.module, method: 'productUnit.getSummary' }) &&
            <MenuButton onClick={goToSummary} color={'dark'}>Ver Stock</MenuButton>
          }

          {hasPermission(permissions, { module: module.module, method: 'transfers.find' }) &&
            <MenuButton onClick={goToTransferences} color={'dark'}>Ver Transferencias</MenuButton>
          }

          {hasPermission(permissions, { module: module.module, method: 'transfers.create' }) &&
            <MenuButton onClick={goToCreateTransfer} color={'dark'}>Crear transferencia</MenuButton>
          }
        </Row>
      </CardBody>
    </Card>
  </SecureView>
}
