import {Dispatch} from "redux";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";

export interface ICreateCompanyProductRequest {
  from: {
    departmentId: string;
    subDepartmentId: string;
  },
  to: {
    departmentId: string;
    subDepartmentId?: string;
  }
}

export default async function (
  dispatch: Dispatch,
  input: ICreateCompanyProductRequest,
  company: IReducedCompany,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('companyStock', 'createTransference')
    .setData(input).setPathValues("COMPANY_ID", company.id)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());
  return response;
}
