import Body from "./Body";
import Sidebar from "./Sidebar";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {useEffect, useState} from "react";
import {redirect, useNavigate, useParams, useRoutes} from "react-router-dom";
import ERoutes from "../../core/enums/ERoutes";
import hasPermission from "../../core/utils/hasPermission";
import {setSession} from "../../core/store/slices/sessionSlice";
import {getSession} from "../../core/utils/sessionStorage";
import bindModuleCommonSettings from "../../core/utils/bindModuleCommonSettings";
import IModule from "../../core/interfaces/IModule";
import {endLoading, startLoading} from "../../core/store/slices/loadingSlice";
import getMyCompanyProfileRequest from "../../core/api/requests/me/getMyCompanyProfileRequest";
import {Row} from "react-bootstrap";
import SeeScannerModal from "../modals/SeeScannerModal";
import {setScanner} from "../../core/store/slices/scannerSlice";
import configuration from "../../configuration";

interface ISecureViewParameters {
  children: any;
  module?: IModule;
  forAdmins?: boolean;
  setModule?(module: IModule): void
}

export default function SecureView(data: ISecureViewParameters) {
  const dispatch = useDispatch();
  const session = useSelector((state: RootState) => state.session)
  const permissions = useSelector((state: RootState) => state.permissions)
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const scanner = useSelector((state: RootState) => state.scanner)

  const params = useParams()

  const navigator = useNavigate()

  const validatePermission = (): void => {
    if (!data.module?.module || !data.module.method) return;

    if (hasPermission(
      permissions,
      {
        module: data.module.module,
        method: data.module.method,
      },
    )) return
  }

  useEffect(() => {


    (async () => {
      if (!selectedCompany.company || selectedCompany.company.id !== params.cid) {
        if (params.cid) {
          await getMyCompanyProfileRequest(dispatch, params.cid || '')
        }
      }
    })()

    // if (!session.token) {
    //   if (params.cid) {
    //     sessionStorage.setItem('lastLocation', btoa(window.location.href))
    //   }
    //   redirect(ERoutes.AUTH_LOGIN)
    // } else {
    //   const lu = sessionStorage.getItem('lastLocation')
    //   if (lu) {
    //     const u = atob(lu)
    //     redirect(u)
    //     sessionStorage.clear()
    //   }
    // }

    const data = getSession()
    if (data) {
      dispatch(setSession(data))
    } else {
      return navigator(ERoutes.AUTH_LOGIN)
    }

    validatePermission();
  }, [])

  useEffect(() => {
    if (!data.module || !data.setModule || data.forAdmins) return;

    if (selectedCompany?.company) {
      if (!data?.module?.module) return;

      const m = selectedCompany.company.modules?.find((moTemp) => moTemp.name === data?.module?.module)
      if (!m) return

      const loadModule = bindModuleCommonSettings(m, data.module)
      data.setModule({
        ...loadModule,
      })
    }
  }, [selectedCompany.company])

  const closeScannerModal = () => {
    dispatch(setScanner({
      showModal: false,
      sessionId: undefined,
    }))
  }

  return <Body>
    <Sidebar />

    <SeeScannerModal
      onClose={closeScannerModal}
      show={scanner.showModal}
    />

    {(data.module?.defined || data.forAdmins) &&
      <div className="page-wrapper secureBody">
        <div className="container-fluid overflow-hidden">
          <Row>
            {data.children &&
              data.children
            }
          </Row>
        </div>
      </div>
    }
  </Body>
}
