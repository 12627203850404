import Swal, {SweetAlertIcon, SweetAlertOptions} from "sweetalert2";
import swalDefaults from "./swalDefaults";
import sweetalert2 from "sweetalert2";
import IRCAlertPostRequest from "./IRCAlertPostRequest";
import IRCAlertPreDelete from "./IRCAlertPreDelete";
import IModule from "../../interfaces/IModule";

export default class RCAlerts {
  private module: IModule;

  constructor(
    module: IModule,
  ) {
    this.module = module;
  }

  public alert(
    icon: SweetAlertIcon,
    title: string,
    text?: string,
  ) {
    Swal.fire({
      ...swalDefaults,
      title,
      text,
      icon
    })
  }

  public preCreate(input: IRCAlertPreDelete, text?: string) {
    Swal.fire({
      ...swalDefaults,
      icon: 'warning',
      title: 'Estas seguro de crear este registro?',
      text,
    }).then((result) => {
      if (result.isConfirmed) {
        input.onAccept()
      } else {
        input.onCancel()
      }
    })
  }

  public preUpdate(input: IRCAlertPreDelete) {
    Swal.fire({
      ...swalDefaults,
      icon: 'warning',
      title: input.text || 'Estas seguro de modificar este registro?',
    }).then((result) => {
      if (result.isConfirmed) {
        input.onAccept()
      } else {
        input.onCancel()
      }
    })
  }

  public preDelete(input: IRCAlertPreDelete) {
    Swal.fire({
      ...swalDefaults,
      icon: 'warning',
      title: 'Estas seguro de eliminar este registro?',
    }).then((result) => {
      if (result.isConfirmed) {
        input.onAccept()
      } else {
        input.onCancel()
      }
    })
  }

  public created(input: IRCAlertPostRequest) {
    const d: SweetAlertOptions = {
      ...swalDefaults,
    }

    if (input.response.statusCode === input.successHttpCode) {
      d.text = `${this.module.singularName} creado exitosamente`
      d.icon = 'success'
    } else {
      d.text = input.response.data.message
      d.icon = 'error'
    }

    Swal.fire(d).then((result) => {
      if (result.isConfirmed) {
        input.onAccept && input.onAccept()
      } else {
        input.onCancel && input.onCancel()
      }
    })
  }

  public update(input: IRCAlertPostRequest) {
    const d: SweetAlertOptions = {
      ...swalDefaults,
    }

    if (input.response.statusCode === input.successHttpCode) {
      d.text = `${this.module.singularName} modificado exitosamente`
      d.icon = 'success'
    } else {
      d.text = input.response.data.message
      d.icon = 'error'
    }

    Swal.fire(d).then((result) => {
      if (result.isConfirmed) {
        input.onAccept && input.onAccept()
      } else {
        input.onCancel && input.onCancel()
      }
    })
  }

  public delete(input: IRCAlertPostRequest) {
    const d: SweetAlertOptions = {
      ...swalDefaults,
    }

    if (input.response.statusCode === input.successHttpCode) {
      d.text = `${this.module.singularName} eliminado exitosamente`
      d.icon = 'success'
    } else {
      d.text = input.response.data.message
      d.icon = 'error'
    }

    Swal.fire(d).then((result) => {
      if (result.isConfirmed) {
        input.onAccept && input.onAccept()
      } else {
        input.onCancel && input.onCancel()
      }
    })
  }
}
