enum EColors {
  RACA_MAIN = '#a54438',

  SUCCESS = '#28A745',
  INFO = '#17A2B8',
  PRIMARY = '#007BFF',
  WARNING = '#FFC107',
  DANGER = '#DC3545',
  DARK = '#343A40',
  SECONDARY = '#6C757D',
  LIGHT = '#F8F9FA',
  WHITE = '#FFFFFF'
}

export default EColors;
