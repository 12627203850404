import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Body from "../../shared/common/Body";
import {Button} from "react-bootstrap";
import {QrScanner} from '@yudiel/react-qr-scanner';
import {useEffect, useState} from "react";
import beep from "../../core/utils/beep";
import ERoutes from "../../core/enums/ERoutes";
import createCompanyScanRequest from "../../core/api/requests/company/company-scanner/createCompanyScanRequest";
import EHttpStatusCode from "../../core/enums/EHttpStatusCodes";

export default function () {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [ token, setToken ] = useState<string>('')
  const [ cid, setCID ] = useState<string>('')

  const [ read, setRead ] = useState<string>('')

  const [ status, setStatus ] = useState<string>('Esperando lectura...')

  useEffect(() => {
    if (read) {
      beep()
      sendScan(
        read,
        {
          readDate: new Date()
        }
      )
    }
  }, [read]);

  const sendScan = async (value: string, data?: any) => {
    if (!token || !cid || !value) return

    setStatus('Enviando...')
    const response = await createCompanyScanRequest(
      dispatch,
      {
        value,
        data,
      },
      cid,
      token,
    )

    if (response.statusCode === EHttpStatusCode.ACCEPTED) {
      setStatus('Enviado correctamente. Esperando nueva lectura...')
    } else {
      setStatus(response.data?.error || 'Error al enviar. Vuelve a intentarlo...')
    }
  }

  useEffect(() => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);

      const t = params.get('t')
      const cid = params.get('cid')
      if (!t || !cid) {
        navigate(ERoutes.HOME)
        return
      }

      setToken(t)
      setCID(cid)
    } else {
      navigate(ERoutes.HOME)
      return
    }
  }, []);

  const setCode = (code: string) => {
    if (read !== code) {
      setRead(code)
    }
  }

  const send = () => {
    sendScan(
      read,
      {
        readDate: new Date()
      }
    )
  }

  return <Body title={'Scanner'}>
    <section id={'scanner'}>
      <div className={'reader'}>
        <QrScanner
          onDecode={(result) => setCode(result)}
          onError={(error) => console.error(error?.message)}
        />
      </div>

      {read &&
        <small>Valor: {read}</small>
      }

      {/*<Button className={'col-12 mt-3 btn-success'} onClick={send}>*/}
      {/*  Enviar*/}
      {/*</Button>*/}
      {status &&
        <small className={'d-block'}>Estado: {status}</small>
      }
    </section>
  </Body>
}
