export default {
  apiRoute: {
    url: 'https://api.bm.racasf.eu/v1',
    ws: 'wss://api.bm.racasf.eu/v1',
  },
  basePath: 'https://bm.racasf.eu',
  application: {
    name: 'Business Manager'
  }
}

