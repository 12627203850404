import {configureStore} from "@reduxjs/toolkit";

import loadingSlice from "./slices/loadingSlice";
import sessionSlice from "./slices/sessionSlice";
import permissionsSlice from "./slices/permissionsSlice";
import availableCompaniesSlice from "./slices/availableCompaniesSlice";
import selectedCompanySlice from "./slices/selectedCompanySlice";
import futilsSlice from "./slices/futilsSlice";
import userMeSlice from "./slices/userMeSlice";
import companyCommons from "./slices/companyCommons";
import scannerSlice from "./slices/scannerSlice";

const store = configureStore({
    reducer: {
        companyCommons: companyCommons,
        loading: loadingSlice,
        session: sessionSlice,
        permissions: permissionsSlice,
        availableCompanies: availableCompaniesSlice,
        selectedCompany: selectedCompanySlice,
        frontendUtilities: futilsSlice,
        userMe: userMeSlice,
        scanner: scannerSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
