import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";
import {setCompanies} from "../../../store/slices/availableCompaniesSlice";

export default async function getMyCompaniesRequest(
  dispatch: Dispatch,
): Promise<IResponse> {
  const request = new RACARequest('me', 'myCompanies')
    .setQueryParams('perPage', '100');

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  if (response.data?.data) {
    dispatch(
      setCompanies(response.data.data)
    );
  }

  return response;
}
