import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";
import {Dispatch} from "redux";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";

export interface ICompanyUserUpdateRoleRequest {
  roleId: string;
}

export default async function (
  dispatch: Dispatch,
  input: ICompanyUserUpdateRoleRequest,
  id: string,
  company: IReducedCompany,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('companyUsers', 'update')
    .setData({
      action: 'basicInformation',
      data: input,
    }).setPathValues('COMPANY_ID', company.id).setPathValues('ID', id);

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
