import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";

export interface ICompanyUserRemoveRequest {
  id: string;
}

export default async function (
  dispatch: Dispatch,
  input: ICompanyUserRemoveRequest,
  company: IReducedCompany,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('companyDevices', 'remove')
    .setPathValues('ID', input.id).setPathValues("COMPANY_ID", company.id)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  )

  dispatch(endLoading());

  return response;
}
