import es from './lang/es'

export default function (word: string, lang: string = 'es'): string {
  if (!word) return word
  let w = word

  switch (lang) {
    case 'es':
      // @ts-ignore
      w = es[word] || w
      break;
    default:
      return w
  }

  return w
}
