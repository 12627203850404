enum EStores {
    LOADING = 'loading',
    SESSION = 'session',
    PERMISSIONS = 'permissions',
    AVAILABLE_COMPANIES = 'availableCompanies',
    SELECTED_COMPANY = 'selectedCompany',
    USER_ME = 'userMe',
    FRONTEND_UTILITIES = 'frontendUtilities',
    COMPANY_COMMONS = 'company.commons',
}

export default EStores;
