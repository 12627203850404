import IModule from "../interfaces/IModule";
import ICompanyModule from "../interfaces/ICompanyModule";
import capitalizeFirstLetter from "./CapitalizeFirstLetter";
import transformToSingular from "./transformToSingular";

export default (
  companyModule: ICompanyModule,
  module: IModule,
): IModule => {
  if (companyModule?.settings?.private) {
    if (companyModule.settings.private.moduleName) {
      module.name = capitalizeFirstLetter(companyModule.settings.private.moduleName)
      module.singularName = transformToSingular(module.name)
    }

    module.private = companyModule.settings.private
  }

  if (companyModule?.settings?.public) {
    module.public = companyModule.settings.public
  }

  module.defined = true
  return module
}
