import {useEffect, useState} from "react";
import {Badge, Button, Form, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import IModule from "../../core/interfaces/IModule";
import IProductUnit from "../../core/interfaces/IProductUnit";
import findByIdCompanyProductsUnitRequest
  from "../../core/api/requests/company/company-products/findByIdCompanyProductsUnitRequest";
import {useParams} from "react-router-dom";
import EHttpStatusCode from "../../core/enums/EHttpStatusCodes";
import IScanner from "../../core/interfaces/IScanner";
import ScannerForModals from "../common/ScannerForModals";
import RCAlerts from "../../core/utils/swal/RCAlerts";

export interface ICompanyCreateDepartmentModal {
  onSubmit(product: IProductUnit): void;
  onClose(): void;
  show: boolean;
  module: IModule;
  code?: string;
  scanner: IScanner;
}

let webSocket: WebSocket;

export default function (data: ICompanyCreateDepartmentModal) {
  const dispatch = useDispatch()
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)

  const [ code, setCode ] = useState<string>('')
  const params = useParams()

  const findProductById = async (id: string) => {
    if (!selectedCompany?.company) return

    const response = await findByIdCompanyProductsUnitRequest(
      dispatch,
      selectedCompany.company,
      id,
    )

    const a = new RCAlerts(data.module)
    if (response.statusCode !== EHttpStatusCode.OK) {
      a.alert(
        'error',
        'Error',
        response.data?.message || 'Error al agregar el producto'
      )
      return
    }

    if (response.data) {
      data.onSubmit(response.data)
      setCode('')
    }
  }

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (data.scanner?.code) {
      setCode(data.scanner.code || '')
    }
  }, [data.scanner.code]);

  useEffect(() => {
    return () => {
      setCode('')
    }
  }, [data.show])

  const setCodeFromScanner = (code: string) => {
    if (!code) return

    if (code.includes("http")) {
      let c = code.split('/')
      c = c[c.length - 1].split('?')
      code = c[0]
    }

    setCode(code)
  }

  const close = () => {
    data.onClose()
  }

  const onPressEnter = (e: any) => {
    e.preventDefault()

    if (!code) return
    findProductById(code)
  }

  return <Modal show={data.show} onHide={close} >
    <Modal.Header>
      <Modal.Title>Agregar Producto</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form onSubmit={onPressEnter}>
        <Form.Group className="mb-3">
          <Form.Label>ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="fefc3907-cf5b-4412-8779-519db8bf4d6e"
            autoFocus
            value={code}
            onChange={(e) => setCodeFromScanner(e.target.value)}
          />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      {/*<ScannerForModals scanner={data.scanner} />*/}

      <Button variant="secondary" onClick={close}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>

}
