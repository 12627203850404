export default (word: string): string => {
  if (!word) return word
  if (word.endsWith("es")) {
    return word.substring(0, word?.length-2)
  } else if (word.endsWith("s")) {
    return word.substring(0, word?.length-1)
  }

  return word
}
