import {useNavigate} from "react-router-dom";
import ERoutes from "../../core/enums/ERoutes";
import {useEffect} from "react";

export default function Home() {
  const navigate = useNavigate()

  useEffect(() => navigate(ERoutes.AUTH_LOGIN), [])

  return <></>
}
