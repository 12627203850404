import {useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {
  ICompanyUserRemoveRequest
} from "../../core/api/requests/company/company-departments/companyDepartmentCreateRequest";
import IModule from "../../core/interfaces/IModule";

export interface ICompanyCreateDepartmentModal {
  onSubmit(data: ICompanyUserRemoveRequest): void;
  onClose(): void;
  show: boolean;
  module: IModule;
}

export default function (data: ICompanyCreateDepartmentModal) {
  const dispatch = useDispatch()
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)

  const [ name, setName ] = useState<string>('')

  const send = () => {
    data.onSubmit({
      name,
    });

    data.onClose();
  }

  useEffect(() => {
    return () => {
      setName('')
    }
  }, [data.show])

  return <Modal show={data.show} onHide={data.onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Agregar {data.module.singularName}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese un nombre..."
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cancelar
      </Button>
      <Button variant="success" onClick={send}>
        Agregar
      </Button>
    </Modal.Footer>
  </Modal>

}
