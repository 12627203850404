import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import requestMiddlewares from "../../requestMiddlewares";

export interface IUserUpdateRequest {
  id: string;
  user: {
    name: string;
    lastName: string;
    roleId: string;
    status: string;
  }
}

export default async function (
  dispatch: Dispatch,
  input: IUserUpdateRequest,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('users', 'update')
    .setPathValues('ID', input.id).setData(input.user);

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  )

  dispatch(endLoading());

  return response;
}
