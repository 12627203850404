import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IReducedCompany from "../../interfaces/reduced/IReducedCompany";
import IDepartment from "../../interfaces/IDepartment";
import IProduct from "../../interfaces/IProduct";

export interface ICompanyCommons {
  departments: IDepartment[];
  products: IProduct[];
}

const initialState: ICompanyCommons = {
  departments: [],
  products: [],
};

const companyCommonsSlice = createSlice({
  name: EStores.COMPANY_COMMONS,
  initialState,
  reducers: {
    setDepartments(state: ICompanyCommons, payload: PayloadAction<IDepartment[]>) {
       return {
         ...state,
         departments: payload.payload,
       }
    },
    setProducts(state: ICompanyCommons, payload: PayloadAction<IProduct[]>) {
      return {
        ...state,
        products: payload.payload,
      }
    }
  },
});

export const {
  setDepartments,
  setProducts,
} = companyCommonsSlice.actions;

export default companyCommonsSlice.reducer;
