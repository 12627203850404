import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";
import IPermission from "../../../../interfaces/IPermission";

export interface IUpdateCompanyRoleInput {
  name: string;
  permissions: IPermission[];
  dashboard: string;
}

export default async function (
  dispatch: Dispatch,
  input: IUpdateCompanyRoleInput,
  company: IReducedCompany,
  id: string,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('companyRoles', 'update')
    .setData(input).setPathValues("COMPANY_ID", company.id).setPathValues('ID', id)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  )

  dispatch(endLoading());

  return response;
}
