import {SweetAlertOptions} from "sweetalert2";
import EColors from "../../enums/EColors";

export default {
  position: 'top-end',
  denyButtonText: 'Cancelar',

  confirmButtonText: 'Confirmar',
  confirmButtonColor: EColors.SUCCESS,
  showConfirmButton: true,

  cancelButtonText: 'Cancelar',
  cancelButtonColor: EColors.DANGER,
  showCancelButton: true,
} as SweetAlertOptions
