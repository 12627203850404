import {useDispatch} from "react-redux";
import {setScanner} from "../../core/store/slices/scannerSlice";

interface cardBodyParameters {
    children: any;
    title?: string;
    qr?: string;
    autoQR?: boolean;
}

export default function CardBody(data: cardBodyParameters) {
    const dispatch = useDispatch()
    const openQR = () => {
        if (!data.qr && !data.autoQR) return

        dispatch(setScanner({
            showModal: true,
            sessionId: data.qr || window.location.href
        }))
    }

    return (
        <div className={"card-body"}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {data.title &&
                  <h4 className="card-title">{data.title}</h4>
                }
                {(data.qr || data.autoQR) &&
                  <button onClick={openQR} className={'btn btn-light ml-2 mb-2'}><i className={'mdi mdi-qrcode'}></i></button>
                }
            </div>
            {data.children && data.children}
        </div>
    )
}
