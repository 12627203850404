import {Dispatch} from "redux";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";

export default async function (
  dispatch: Dispatch,
  company: IReducedCompany,
  transferenceId: string,
  id: string,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('companyStock', 'removeProduct')
    .setPathValues("COMPANY_ID", company.id)
    .setPathValues('ID', id)
    .setPathValues('TID', transferenceId)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());
  return response;
}
