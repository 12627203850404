import SecureView from "../../shared/common/SecureView";
import {Alert, Badge, Button, Form, InputGroup, Row} from "react-bootstrap";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import Paginator from "../../shared/common/Paginator";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import hasPermission from "../../core/utils/hasPermission";
import {useEffect, useState} from "react";
import IUser from "../../core/interfaces/IUser";
import findUsersRequest from "../../core/api/requests/users/findUsersRequest";
import {Link, useNavigate} from "react-router-dom";
import ERoutes from "../../core/enums/ERoutes";
import IModule from "../../core/interfaces/IModule";
import moment from "moment";

const m: IModule = {
  module: EModules.USERS,
  method: ECommonPermissions.FIND,
  name: 'Clientes',
  singularName: 'Cliente',
}

export default function UsersList() {
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [ module, setModule ] = useState<IModule>(m)

  const [ users, setUsers ] = useState<IUser[]>([]);

  const [ count, setCount ] = useState<number>(0);

  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');

  const changePageAndFind = (page: number) => {
    find(page);
  }

  const find = async (
    pageByParam?: number,
  ) => {
    if (pageByParam) setPage(pageByParam)

    const response = await findUsersRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
    )

    if (response?.data) {
      setUsers(response.data?.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)

    }
  }

  useEffect(() => {
    find()
  }, [])

  useEffect(() => {
    setPage(1)
  }, [query])

  return <SecureView module={module} setModule={setModule} forAdmins>
      <Card className={'col-12'}>
        <CardBody title={`${module.name} (${count})`}>
          <div className={'row mt-5'}>
            <div className={'col-9'}>
              <InputGroup>
                <Form.Control
                  placeholder="Buscar por nombre... o email"
                  aria-label="Buscar por nombre... o email"
                  aria-describedby="Buscar por nombre... o email"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={(key) => key.key === 'Enter' && find(1)}
                />
                <Button
                  variant="outline-primary"
                  id="button-addon2"
                  onClick={() => find(1)}
                >
                  <i className={'ti-search'}></i> Buscar
                </Button>
              </InputGroup>
            </div>

            <div className={'col-3'}>
              <Link
                to={ERoutes.USERS_CREATE}
                className="btn btn-info float-right"
              >Nuevo {module.singularName}
              </Link>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered m-t-30 table-hover contact-list">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Estado</th>
                  <th>Rol</th>
                  <th>Fecha creación</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
              {users.map((user) => {
                return <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.name} {user.lastName}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.status === 'active' ?
                      <Badge text={'white'} bg={'success'}>activado</Badge>
                      :
                      <Badge text={'white'} bg={'warning'}>{user.status}</Badge>
                    }
                  </td>
                  <td>{user.role.name}</td>
                  <td>{moment(user.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                  <td>
                    {hasPermission(permissions, { module: module.module, method: ECommonPermissions.FIND_BY_ID }) &&
                        <Button size={'sm'} variant={'secondary'} onClick={() => navigator(ERoutes.USERS_VIEW.replace(':id', user.id))}>
                            <i className={'ti-eye'}></i> Ver
                        </Button>
                    }
                  </td>
                </tr>
              })
              }
              </tbody>
            </table>

            <Paginator
              total={totalPages}
              page={page}
              goTo={changePageAndFind}
            />
          </div>
        </CardBody>
      </Card>
  </SecureView>
}
