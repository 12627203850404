import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IFrontendUtilities from "../../interfaces/IFrontendUtilities";
import IColors from "../../interfaces/IColors";
import colors from "../../configurations/colors";

type SliceType = {
  futils?: IFrontendUtilities;
  colors: IColors;
}

const initialState: SliceType = {
  colors: colors.light,
};

const slice = createSlice({
  name: EStores.FRONTEND_UTILITIES,
  initialState,
  reducers: {
    setFU(state: SliceType, data: PayloadAction<IFrontendUtilities>) {
      return {
        ...state,
        futils: data.payload,
      }
    },
  },
});

export const {
  setFU,
} = slice.actions;

export default slice.reducer;
