import {Button, Col, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import {useEffect, useState} from "react";
import IOrderProduct from "../../../core/interfaces/IOrderProduct";
import IProduct from "../../../core/interfaces/IProduct";
import ICurrency from "../../../core/interfaces/ICurrency";
import {ITax} from "../../../core/interfaces/IFrontendUtilities";
import findCompanyProductsRequest from "../../../core/api/requests/company/company-products/findCompanyProductsRequest";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";
import companyOrderCreateRequest, {
  ICompanyOrderProductRequest
} from "../../../core/api/requests/company/company-orders/companyOrderCreateRequest";

export default function () {
  const dispatch = useDispatch()
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany);
  const user = useSelector((state: RootState) => state.userMe);
  const futils = useSelector((state: RootState) => state.frontendUtilities);

  const [ cart, setCart ] = useState<IOrderProduct[]>([]);

  const [ query, setQuery ] = useState<string>('');
  const [ productList, setProductList ] = useState<IProduct[]>([])

  const [ currency, setCurrency ] = useState<ICurrency | null>(null);
  const [ taxes, setTaxes ] = useState<ITax[]>([]);
  const [ subtotal, setSubtotal ] = useState<number>(0);
  const [ total, setTotal ] = useState<number>(0);

  useEffect(() => {
    if (selectedCompany.company) {
      findProduct();
    }
  }, [selectedCompany.company])

  const findProduct = async () => {
    if (!selectedCompany.company) return;

    const response = await findCompanyProductsRequest(
      dispatch,
      {
        query,
        page: 1,
        perPage: 10,
      },
      selectedCompany.company,
    );

    if (response.statusCode === EHttpStatusCodes.OK && response.data) {
      setProductList(response.data.data);
    }
  }

  const create = () => {
    if (!cart.length) {
      sweetalert2.fire(
        'Atención',
        'debes agregar un producto para realizar un pedido',
        'warning'
      );
      return
    };

    sweetalert2.fire({
      title: 'Número del pedido',
      input: 'text',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Solicitar',
    }).then((d) => {
      if (d.isConfirmed) {
        createOrder(d.value || Date.now().toString())
      }
    })
  }

  const createOrder = async (orderReferenceId: string) => {
    if (!selectedCompany.company) return;

    const products = cart.map((p): ICompanyOrderProductRequest => ({
      productId: p.product.id || '',
      count: p.count,
      notes: p.notes || '-',
    }));

    const response = await companyOrderCreateRequest(
      dispatch,
      {
        products,
        status: 'requested',
        orderReferenceId,
        client: {},
      },
      selectedCompany.company,
    );

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Orden creada exitosamente`,
        'success',
      ).then(() => cancel())
    } else {
      sweetalert2.fire(
        `Error`,
        response.data.message,
        'error',
      )
    }

  }

  const cancel = () => {
    setCart([]);
    setQuery('')
    findProduct()
  }

  const addProduct = (
    product: IProduct,
  ) => {
    if (currency && currency.code !== product.price.currency.code) {
      return
    } else if (!currency) {
      setCurrency(product.price.currency)
    }

    const exists = cart.find(p => p.product.id === product.id);
    if (!exists) {
      setCart((prevState) => [
        ...prevState,
        {
          product,
          count: 1,
          notes: '',
        }
      ])
    } else {
      modifyProductCount(exists, 1)
    }
  }

  const modifyProductCount = (
    orderProduct: IOrderProduct,
    count: number,
  ) => {
    let temp = [...cart]
    const findIndex = temp.findIndex(p => p.product.id === orderProduct.product.id);
    if (findIndex < 0) return;

    temp[findIndex].count += count

    if (cart[findIndex].count <= 0) {
      temp = temp.filter(p => p.product.id !== orderProduct.product.id)
    }

    setCart([ ...temp ])
  }

  const addProductNote = (
    orderProduct: IOrderProduct
  ): void => {
    sweetalert2.fire({
      title: 'Agregar notas',
      input: 'text',
      inputValue: orderProduct.notes || '',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Agregar',
    }).then((d) => {
      if (!d.isConfirmed) return

      setProductNote(orderProduct, d.value)
    })
  }

  const setProductNote = (
    orderProduct: IOrderProduct,
    note: string,
  ) => {
    let temp = [...cart]
    const findIndex = temp.findIndex(p => p.product.id === orderProduct.product.id);
    if (findIndex < 0) return;

    temp[findIndex].notes = note;

    setCart([ ...temp ])
  }

  useEffect(() => {
    const subtotalTemp = calculateSubtotal();
    setSubtotal(subtotalTemp);

    let taxesAmount = 0;
    (futils?.futils?.taxes || []).forEach((t) => {
      taxesAmount += calculateTaxPrice(subtotalTemp, t);
    });

    const totalTemp = subtotalTemp + taxesAmount;
    setTotal(totalTemp)
  }, [cart])

  const calculateTotalByProduct = (
    product: IOrderProduct,
  ): number => {
    return product.product.price.amount * product.count;
  }

  const calculateSubtotal = (): number => {
    let amount = 0;

    cart.forEach(prod => {
      amount += calculateTotalByProduct(prod);
    })

    return amount
  }

  const calculateTaxPrice = (subtotal: number, tax: ITax): number => {
    let temp = 0;

    temp += tax.value.absolute;

    temp += (subtotal * tax.value.relative);

    return temp;
  }

  useEffect(() => {
    const to = setTimeout(() => {
      findProduct()
    }, 500)

    return () => clearTimeout(to);
  }, [query])

  return <Row className={'mt-5'}>
    <Col xs={6}>
      <input
        type={'text'}
        className={'input-sku'}
        placeholder={'ingrese SKU o nombre del producto'}
        onChange={(e) => setQuery(e.target.value)}
      />

      <div className={'product-searcher'}>
        <table className={'table table-bordered m-t-10 table-hover'}>
          <thead>
          <tr>
            <th>Nombre</th>
            <th>Precio</th>
            <th>Seleccionar</th>
          </tr>
          </thead>
          <tbody>
          {productList.map(prod => {
            if (currency && prod.price.currency.code !== currency.code) return;

            return <tr key={prod.id}>
              <td>{prod.name}</td>
              <td>{prod.price.currency.symbol} {prod.price.amount}</td>
              <td>
                <Button variant={'info'} size={'lg'} onClick={() => addProduct(prod)}>
                  Agregar
                </Button>
              </td>
            </tr>
          })
          }
          </tbody>
        </table>
      </div>
    </Col>
    <Col xs={6}>
      <div className={'order-header'}>
        <h2>Orden</h2>
      </div>
      <div className={'order-cart'}>
        <table className={'table table-primary table-bordered m-t-10 table-hover'}>
          <thead>
          <tr>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Precio U.</th>
            <th>Total</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {cart.map(c => {
            return <tr key={c.product.id}>
              <td>{c.product.name}</td>
              <td>{c.count} {c.product.measurementUnit}</td>
              <td>{c.product.price.currency.symbol} {c.product.price.amount}</td>
              <td>{c.product.price.currency.symbol} {calculateTotalByProduct(c)}</td>
              <td>
                <Button variant={'primary mr-4'} onClick={() => addProductNote(c)} size={'sm'}>
                  <i className={'ti-pencil-alt'}></i>
                </Button>
                <Button variant={'danger'} onClick={() => modifyProductCount(c, -1)} size={'sm'}>
                  <i className={'ti-minus'}></i>
                </Button>
                <Button variant={'success ml-2'} onClick={() => modifyProductCount(c, 1)} size={'sm'}>
                  <i className={'ti-plus'}></i>
                </Button>
              </td>
            </tr>
          })
          }
          </tbody>
        </table>
      </div>
      <hr />
      <div className={'order-footer'}>
        <table className={'table table-bordered m-t-10 table-hover'}>
          <tbody>
          <tr>
            <td>Subtotal</td>
            <td>{currency?.symbol || ''} {subtotal}</td>
          </tr>
          {(futils?.futils?.taxes || []).map((t) => {
            return <tr>
              <td>{t.name}
                {t.value.relative ? ` (${t.value.relative * 100}%)` : ''}
                {t.value.absolute ? ` (${currency?.symbol || ''}${t.value.absolute})` : ''}
              </td>
              <td>{currency?.symbol || ''} {calculateTaxPrice(subtotal, t)}</td>
            </tr>
          })
          }
          </tbody>
        </table>
      </div>
      <h2 className={'total-number'}>Total: {currency?.symbol || ''} {total}</h2>

      <div className={'row mt-5'}>
        <div className={'col-6'}>
          <Button size={'lg'} variant={'success'} onClick={create} className={'button-confirm-order'}>
            Crear Orden
          </Button>
        </div>

        <div className={'col-6'}>
          <Button size={'lg'} variant={'danger'} onClick={cancel} className={'button-confirm-order'}>
            Cancelar Orden
          </Button>
        </div>
      </div>
    </Col>
  </Row>
}
