import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import requestMiddlewares from "../../requestMiddlewares";

export interface IPasswordRecoveryRequest {
  email: string;
}

export default async function passwordRecoveryRequest(
  dispatch: Dispatch,
  input: IPasswordRecoveryRequest,
): Promise<IResponse> {
  dispatch(startLoading())

  const request = new RACARequest('auth', 'password-recovery-request')
    .setData(input);

  const response = await request.makeRequest()
  requestMiddlewares(
    dispatch,
    response,
  )

  dispatch(endLoading())

  return response;
}
