import SecureView from "../../../shared/common/SecureView";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import IPermission from "../../../core/interfaces/IPermission";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Badge} from "react-bootstrap";
import Paginator from "../../../shared/common/Paginator";
import IOrder from "../../../core/interfaces/IOrder";
import findCompanyOrdersRequest from "../../../core/api/requests/company/company-orders/findCompanyOrdersRequest";
import moment from "moment";
import hasPermission from "../../../core/utils/hasPermission";
import sweetalert2 from "sweetalert2";
import companyOrdersUpdateStatusRequest
  from "../../../core/api/requests/company/company-orders/companyOrdersUpdateStatusRequest";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import CompanyViewOrderEvenetsModal from "../../../shared/modals/CompanyViewOrderEvenetsModal";
import FindByIdCompanyOrdersRequest
  from "../../../core/api/requests/company/company-orders/findByIdCompanyOrdersRequest";
import tlt from "../../../core/api/helpers/translator/translator";
import cancelationReasons from "../../../core/api/helpers/cancelationReasons";

const permission: IPermission = {
  module: EModules.COMPANY_ORDERS,
  method: ECommonPermissions.FIND,
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();

  const [ data, setData ] = useState<IOrder[]>([])

  const [ count, setCount ] = useState<number>(0);
  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');

  const [ selectedOrderView, setSelectedOrderView ] = useState<IOrder | null>(null);

  const changePageAndFind = (page: number) => find(page)

  const find = async (
    pageByParam?: number,
  ) => {
    if (!selectedCompany.company) return;

    if (pageByParam) setPage(pageByParam)

    const response = await findCompanyOrdersRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
      selectedCompany.company,
    )

    if (response?.data) {
      setData(response.data?.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)
    }
  }

  const cancelOrderRequest = async (
    order: IOrder,
    reason: string,
  ) => {
    if (!selectedCompany.company) return;

    const response = await companyOrdersUpdateStatusRequest(
      dispatch,
      {
        order,
        status: 'cancelled',
        description: reason,
      },
      selectedCompany.company,
    );

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Orden cancelada exitosamente`,
        'success',
      ).then(() => find(1))
    } else {
      sweetalert2.fire(
        `Error`,
        response.data.message,
        'error',
      )
    }
  }

  const openModalEvents = async (order: IOrder) => {
    if (!selectedCompany.company) return

    const updatedOrder = await FindByIdCompanyOrdersRequest(
      dispatch,
      { id: order.id },
      selectedCompany.company,
    );

    if (updatedOrder.statusCode === EHttpStatusCodes.OK && updatedOrder.data) {
      setSelectedOrderView(updatedOrder.data)
    }
  }

  const cancelOrder = (order: IOrder) => {
    sweetalert2.fire({
      title: 'Motivo de cancelación',
      input: 'select',
      inputOptions: cancelationReasons,
      showCancelButton: true,
      cancelButtonText: 'Cerrar',
      confirmButtonText: 'Cancelar pedido',
    }).then((d) => {
      if (d.isConfirmed) {
        cancelOrderRequest(order, d.value)
      }
    })
  }

  useEffect(() => {
    find()
  }, [selectedCompany.company])

  useEffect(() => {
    setPage(1)
  }, [query])

  return <SecureView>

    <CompanyViewOrderEvenetsModal
      order={selectedOrderView}
      onClose={() => setSelectedOrderView(null)}
    />

    <Card className={'col-12'}>
      <CardBody title={`Ordenes ${count}`}>
        <div className="table-responsive">
          <table className="table table-bordered m-t-30 table-hover contact-list">
            <thead>
            <tr>
              <th>Numero pedido</th>
              <th>Cant. Productos</th>
              <th>Estado</th>
              <th>Fecha</th>
              <th>Ultima edición</th>
              <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            {data.map((order) => {
              return <tr key={order.id}>
                <td>{order.orderReferenceId}</td>
                <td>{order.products?.length}</td>
                <td>
                  {order.status === 'delivered' &&
                      <Badge bg={'success'}>{tlt(order.status)}</Badge>
                  }
                  {order.status === 'cancelled' &&
                      <Badge bg={'danger'}>{tlt(order.status)}</Badge>
                  }
                  {order.status !== 'cancelled' && order.status !== 'delivered' &&
                      <Badge bg={'primary'}>{tlt(order.status)}</Badge>
                  }
                </td>
                <td>{moment(order.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td>{order.updateContext?.fromUser}</td>
                <td>
                  {hasPermission(permissions, { module: EModules.COMPANY_ORDERS, method: ECommonPermissions.FIND_BY_ID }) &&
                      <button onClick={() => openModalEvents(order)} className='btn btn-info btn-sm'><i className={'ti-timer'}></i></button>
                  }
                  {hasPermission(permissions, { module: EModules.COMPANY_ORDERS, method: ECommonPermissions.PARTIAL_UPDATE }) &&
                      <button onClick={() => cancelOrder(order)} className='btn ml-2 btn-outline-danger btn-sm'><i className={'ti-na'}></i></button>
                  }
                </td>
              </tr>
            })
            }
            </tbody>
          </table>

          <Paginator
            total={totalPages}
            page={page}
            goTo={changePageAndFind}
          />
        </div>
      </CardBody>
    </Card>
  </SecureView>
}
