import Body from "../../../shared/common/Body";
import {useState} from "react";
import {Alert} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";
import passwordRecoveryRequest from "../../../core/api/requests/auth/passwordRecoveryRequest";
import {useDispatch} from "react-redux";

export default function PasswordRecovery() {
  const [ error, setError ] = useState<string>('')
  const [ email, setEmail ] = useState<string>('')

  const dispatch = useDispatch();
  const navigator = useNavigate()

  const request = async (e: any) => {
    e.preventDefault()
    if (!email) return;

    await passwordRecoveryRequest(
      dispatch,
      {
        email,
      },
    );

    sweetalert2.fire(
      `Excelente`,
      `Solicitud de recuperación de contraseña creada exitosamente. Si el usuario existe, le llegará un mail a la casilla de correo ${email}`,
      'success',
    ).then(() => {
      navigator(ERoutes.AUTH_LOGIN)
    })
  }

  return (
    <Body title={'password recovery'}>
      <section id="wrapper" className={"fullWidth"}>
        <div className="login-register login-picture">
          <div className="login-box card">
            <div className="card-body">
              <form className="form-horizontal form-material" onSubmit={request}>
                <h3 className="text-center m-b-20">Recuperar contraseña</h3>
                <div className="form-group ">
                  <div className="col-xs-12">
                    <input className="form-control" type="email" required={true} placeholder="Email" value={email} onChange={(e) => setEmail(e.target?.value)} />
                  </div>
                </div>

                {error &&
                    <div className="form-group text-center">
                        <div className="col-xs-12">
                            <Alert>{error}</Alert>
                        </div>
                    </div>
                }

                <div className="form-group text-center">
                  <div className="col-xs-12 p-b-20">
                    <button className="btn btn-block btn-lg btn-info btn-rounded" type="submit">Iniciar solicitud</button>
                  </div>
                </div>
                <div className="form-group m-b-0">
                  <div className="col-sm-12 text-center">Ya tienes cuenta?
                    <Link to={ERoutes.AUTH_LOGIN} className="text-info m-l-5"><b>Login</b></Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Body>
  )
}
