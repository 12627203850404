import {Route, Routes} from "react-router-dom";
import ERoutes from "../enums/ERoutes";
import React from "react";
import Dashboard from "../../components/dashboard/Dashboard";
import UsersList from "../../components/users/UsersList";
import Logout from "../../components/auth/logout/Logout";
import CompaniesList from "../../components/companies/CompaniesList";
import UserNew from "../../components/users/UserNew";
import UserModify from "../../components/users/UserModify";
import CompanyNew from "../../components/companies/CompanyNew";
import CompanyUserList from "../../components/company/company-users/CompanyUserList";
import CompanyProductList from "../../components/company/company-products/CompanyProductList";
import CompanyProductModify from "../../components/company/company-products/CompanyProductModify";
import CompanyProductNew from "../../components/company/company-products/CompanyProductNew";
import CompanyOrderList from "../../components/company/company-orders/CompanyOrderList";
import CompanyDevicesList from "../../components/company/company-devices/CompanyDevicesList";
import CompanyDepartmentList from "../../components/company/company-departments/CompanyDepartmentList";
import Reports from "../../components/reports/Reports";
import NoPage from "../../shared/common/NoPage";
import CompanyStockViewMain from "../../components/company/company-stock/CompanyStockViewMain";
import CompanyRoleList from "../../components/company/company-roles/CompanyRoleList";
import CompanyCreateStockTransferScreen from "../../components/company/company-stock/CompanyCreateStockTransferScreen";
import CompanyViewStockTransfersScreen from "../../components/company/company-stock/CompanyViewStockTransfersScreen";
import CompanyListStockTransfersScreen from "../../components/company/company-stock/CompanyListStockTransfersScreen";
import CompanyStockSummaryScreen from "../../components/company/company-stock/CompanyStockSummaryScreen";
import CompanyCreateStockScreen from "../../components/company/company-stock/CompanyCreateStockScreen";
import PrintTagScreen from "../../components/printer/PrintTagScreen";

export default function SecureRoutes() {
  return <Routes>
    <Route path={ERoutes.DASHBOARD} element={<Dashboard />} />

    <Route path={ERoutes.USERS_LIST} element={<UsersList />} />
    <Route path={ERoutes.USERS_CREATE} element={<UserNew />} />
    <Route path={ERoutes.USERS_VIEW} element={<UserModify />} />

    <Route path={ERoutes.PRINT_TAG} element={<PrintTagScreen />} />

    <Route path={ERoutes.COMPANIES_LIST} element={<CompaniesList />} />
    <Route path={ERoutes.COMPANIES_CREATE} element={<CompanyNew />} />

    <Route path={ERoutes.AUTH_LOGOUT} element={<Logout />} />

    <Route path={ERoutes.COMPANY_USERS_LIST} element={<CompanyUserList />} />

    <Route path={ERoutes.COMPANY_ROLES_LIST} element={<CompanyRoleList />} />

    <Route path={ERoutes.COMPANY_PRODUCTS_LIST} element={<CompanyProductList />} />
    <Route path={ERoutes.COMPANY_PRODUCTS_VIEW} element={<CompanyProductModify />} />
    <Route path={ERoutes.COMPANY_PRODUCTS_CREATE} element={<CompanyProductNew />} />

    <Route path={ERoutes.COMPANY_ORDERS_LIST} element={<CompanyOrderList />} />

    <Route path={ERoutes.COMPANY_DEVICES_LIST} element={<CompanyDevicesList />} />

    <Route path={ERoutes.COMPANY_DEPARTMENTS_LIST} element={<CompanyDepartmentList />} />

    <Route path={ERoutes.COMPANY_STOCK_VIEW} element={<CompanyStockViewMain />} />
    <Route path={ERoutes.COMPANY_STOCK_NEW} element={<CompanyCreateStockScreen />} />
    <Route path={ERoutes.COMPANY_STOCK_VIEW_SUMMARY} element={<CompanyStockSummaryScreen />} />
    <Route path={ERoutes.COMPANY_STOCK_TRANSFERENCES_LIST} element={<CompanyListStockTransfersScreen />} />
    <Route path={ERoutes.COMPANY_STOCK_TRANSFERENCES_CREATE} element={<CompanyCreateStockTransferScreen />} />
    <Route path={ERoutes.COMPANY_STOCK_TRANSFERENCES_VIEW} element={<CompanyViewStockTransfersScreen />} />

    <Route path={ERoutes.COMPANY_REPORTS} element={<Reports />} />

    {/*<Route path={'*'} element={<NoPage />} />*/}
  </Routes>
}
