import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IReducedCompany from "../../interfaces/reduced/IReducedCompany";

const initialState: IReducedCompany[] = [];

const availableCompaniesSlice = createSlice({
  name: EStores.AVAILABLE_COMPANIES,
  initialState,
  reducers: {
    setCompanies(state: IReducedCompany[], data: PayloadAction<IReducedCompany[]>) {
      return data.payload;
    },
  },
});

export const {
  setCompanies,
} = availableCompaniesSlice.actions;

export default availableCompaniesSlice.reducer;
