import {Dispatch} from 'redux';
import {IResponse} from './Request';
import EHttpStatusCode from "../enums/EHttpStatusCodes";
import {logout} from "../store/slices/sessionSlice";

export default function ErrorManager(
  dispatch: Dispatch,
  response: IResponse,
): IResponse {
  switch (response.statusCode) {
    case EHttpStatusCode.BAD_REQUEST:
      break;
    case EHttpStatusCode.UNAUTHORIZED:
      dispatch(logout())
      break;
    default:
      return response
  }

  return response;
}
