import {useEffect} from "react";
import configuration from "../../configuration";
import Loader from "../lib/loader/loader";

interface bodyParameters {
    children: any;
    title?: string;
}

export default function Body({ children }: bodyParameters) {
    useEffect(() => {
        document.title = `${configuration.application.name}`

        if (children.title) {
            document.title += ` | ${children.title}`
        }
    }, [])

    return <>
        <Loader />
        {children}
    </>
}
