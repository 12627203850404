import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IReducedCompany from "../../interfaces/reduced/IReducedCompany";
type SliceType = {
  company?: IReducedCompany;
}

const initialState: SliceType = {};

const slice = createSlice({
  name: EStores.SELECTED_COMPANY,
  initialState,
  reducers: {
    setCompany(state: SliceType, data: PayloadAction<IReducedCompany>) {
      const s = {
        ...state,
        company: data.payload,
      }

      // localStorage.setItem('company', JSON.stringify(s))
      return s
    },
  },
});

export const {
  setCompany,
} = slice.actions;

export default slice.reducer;
