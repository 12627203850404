import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import SecureView from "../../shared/common/SecureView";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import {RootState} from "../../core/store/store";
import {useNavigate, useParams} from "react-router-dom";
import IUser from "../../core/interfaces/IUser";
import ERoutes from "../../core/enums/ERoutes";
import userFindByIdRequest from "../../core/api/requests/users/userFindByIdRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import EHttpStatusCode from "../../core/enums/EHttpStatusCodes";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import {Button, Form, Row} from "react-bootstrap";
import findRolesRequest from "../../core/api/requests/roles/findRolesRequest";
import IRole from "../../core/interfaces/IRole";
import moment from "moment";
import sweetalert2 from "sweetalert2";
import userRemoveRequest from "../../core/api/requests/users/userRemoveRequest";
import userUpdateRequest, {IUserUpdateRequest} from "../../core/api/requests/users/userUpdateRequest";
import IModule from "../../core/interfaces/IModule";
import RCAlerts from "../../core/utils/swal/RCAlerts";

const m: IModule = {
  module: EModules.USERS,
  method: ECommonPermissions.CREATE,
  name: 'Clientes',
  singularName: 'Cliente',
}

export default function () {
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const params = useParams();
  const [ module, setModule ] = useState<IModule>(m)

  const [ roles, setRoles ] = useState<IRole[]>([]);

  const [ user, setUser ] = useState<IUser>();

  const goBack = () => navigator(ERoutes.USERS_LIST)

  const get = async (id: string) => {
    const response = await userFindByIdRequest(
      dispatch,
      { id }
    );

    if (response.statusCode === EHttpStatusCodes.OK && response.data) {
      setUser(response.data)
    } else goBack()
  }

  const setUserValue = (
    field: string,
    value: any,
  ) => {
    if (!user) return

    const userTemp = user

    switch (field) {
      case 'roleId':
        const role = roles.find((r) => r.id === value);
        if (role) {
          userTemp.role = role;
        }
        break;
      case 'name':
        userTemp.name = value;
        break;
      case 'lastName':
        userTemp.lastName = value;
        break;
      default:
    }

    setUser({
      ...userTemp
    })
  }

  const findRoles = async () => {
    const response = await findRolesRequest(
      dispatch,
      {
        query: '',
        page: 1,
        perPage: 100,
      },
    )

    if (response?.data?.data) {
      setRoles(response.data.data);
    }
  }

  const removeUser = async () => {
    const s = new RCAlerts(module)

    s.preDelete({
      async onAccept() {
        removeUserConfirmed()
      },
      onCancel() {}
    })
  }

  const removeUserConfirmed = async () => {
    if (!user) return;
    const response = await userRemoveRequest(
      dispatch,
      {
        id: user.id
      }
    )

    const s = new RCAlerts(module)
    s.delete({
      response,
      successHttpCode: EHttpStatusCode.OK,
    })
  }

  const updateUser = async () => {
    if (!user) return;

    const data: IUserUpdateRequest = {
      id: user.id,
      user: {
        name: user.name,
        lastName: user.lastName,
        roleId: user.role.id,
        status: user.status,
      }
    }

    const response = await userUpdateRequest(
      dispatch,
      data,
    );

    new RCAlerts(module).update({
      response,
      successHttpCode: EHttpStatusCode.ACCEPTED,
      onAccept() {
        setUser(response.data);
      }
    })
  }

  useEffect(() => {
    findRoles()

    if (params?.id) {
      get(params.id);
    } else goBack();
  }, [])

  return <SecureView module={module} setModule={setModule} forAdmins>
    <Card className={'col-12'}>
      <CardBody>
        <Row>
          <Form.Group className="mb-3 col-6">
            <Form.Label>ID</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={user?.id}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Fecha de alta</Form.Label>
            <Form.Control
              type="text"
              value={user?.createdAt && moment(user.createdAt).format('DD/MM/YYYY HH:mm:ss')}
              disabled
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3 col-6">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="John"
              value={user?.name}
              onChange={(e) => setUserValue('name', e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Apellido</Form.Label>
            <Form.Control
              type="text"
              placeholder="Doe"
              value={user?.lastName}
              onChange={(e) => setUserValue('lastName', e.target.value)}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group className="mb-3 col-6">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="example@racasf.eu"
              value={user?.email}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Rol</Form.Label>
            <select
              className={'custom-select'}
              value={user?.role.id}
              onChange={(e) => setUserValue('roleId', e.target.value)}
            >
              <option>Seleccionar rol</option>
              {roles.map((r) => {
                return <option
                  key={r.id}
                  value={r.id}
                  selected={r.id === user?.role.id}
                >
                  {r.name}
                </option>
              })
              }
            </select>
          </Form.Group>
        </Row>

        <Button variant="success" type="button" onClick={updateUser} className={'mt-3'}>
          <i className='ti-save'></i> Guardar
        </Button>

        <Button variant="danger" type="button" onClick={removeUser} className={'mt-3 ml-2'}>
          <i className='ti-trash'></i> Eliminar
        </Button>

        <Button variant="secondary" type="button" onClick={goBack} className={'mt-3 ml-2'}>
          Cancelar
        </Button>
      </CardBody>
    </Card>
  </SecureView>
}
