import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../../../Request";
import {endLoading, startLoading} from "../../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../../requestMiddlewares";
import IReducedCompany from "../../../../../interfaces/reduced/IReducedCompany";

export default async function (
  dispatch: Dispatch,
  company: IReducedCompany,
  departmentId: string,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('companySubdepartments', 'list')
    .setPathValues('DID', departmentId).setPathValues('COMPANY_ID', company.id)
    .setQueryParameters({
      perPage: 100,
    })

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
