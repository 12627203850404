import SecureView from "../../../shared/common/SecureView";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import IPermission from "../../../core/interfaces/IPermission";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import {useEffect, useState} from "react";
import IProduct from "../../../core/interfaces/IProduct";
import {Button, Form, InputGroup} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes";
import Paginator from "../../../shared/common/Paginator";
import findCompanyProductsRequest from "../../../core/api/requests/company/company-products/findCompanyProductsRequest";
import hasPermission from "../../../core/utils/hasPermission";
import IModule from "../../../core/interfaces/IModule";

const m: IModule = {
  module: EModules.COMPANY_PRODUCTS,
  method: ECommonPermissions.FIND,
  name: 'Productos',
  singularName: 'Producto',
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const [ module, setModule ] = useState<IModule>(m)

  const navigate = useNavigate();

  const [ data, setData ] = useState<IProduct[]>([])

  const [ count, setCount ] = useState<number>(0);
  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');

  const changePageAndFind = (page: number) => find(page)

  const find = async (
    pageByParam?: number,
  ) => {
    if (!selectedCompany.company) return;

    if (pageByParam) setPage(pageByParam)

    const response = await findCompanyProductsRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
      selectedCompany.company,
    )

    if (response?.data) {
      setData(response.data?.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)
    }
  }

  const redirectToProduct = (id: string) => {
    if (!id || !selectedCompany?.company?.id) return;

    navigate(ERoutes.COMPANY_PRODUCTS_VIEW.replace(':cid', selectedCompany?.company?.id || '').replace(':id', id || ''))
    return
  }

  useEffect(() => {
    find()
  }, [selectedCompany.company])

  useEffect(() => {
    setPage(1)
  }, [query])

  return <SecureView module={module} setModule={setModule}>
    <Card className={'col-12'}>
      <CardBody title={`${module.name} (${count})`}>

        <div className={'row mt-5'}>
          <div className={'col-9'}>
            <InputGroup>
              <Form.Control
                placeholder="Buscar por nombre..."
                aria-label="Buscar por nombre..."
                aria-describedby="Buscar por nombre..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(key) => key.key === 'Enter' && find(1)}
              />
              <Button
                variant="outline-primary"
                id="button-addon2"
                onClick={() => find(1)}
              >
                <i className={'ti-search'}></i> Buscar
              </Button>
            </InputGroup>
          </div>

          <div className={'col-3'}>
            {hasPermission(permissions, { module: EModules.COMPANY_PRODUCTS, method: ECommonPermissions.CREATE }) &&
                <Button
                    type={'button'}
                    onClick={() => navigate(ERoutes.COMPANY_PRODUCTS_CREATE)}
                    className="btn btn-info float-right"
                >Nuevo Producto
                </Button>
            }
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered m-t-30 table-hover contact-list">
            <thead>
            <tr>
              <th>SKU</th>
              <th>Nombre</th>
              <th>Categoria</th>
              <th>Stock</th>
              <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            {data.map((products) => {
              return <tr key={products.id}>
                <td>{products.sku}</td>
                <td>{products.name}</td>
                <td>{products.category}</td>
                <td>{products.stock} {products.measurementUnit}</td>
                <td>
                  {hasPermission(permissions, { module: module.module, method: ECommonPermissions.FIND_BY_ID }) &&
                      <Button size={'sm'} variant={'secondary'} onClick={() => redirectToProduct(products?.id || '')}>
                          <i className={'ti-eye'}></i> Ver
                      </Button>
                  }
                </td>
              </tr>
            })
            }
            </tbody>
          </table>

          <Paginator
            total={totalPages}
            page={page}
            goTo={changePageAndFind}
          />
        </div>

      </CardBody>
    </Card>
  </SecureView>
}
