import SecureView from "../../../shared/common/SecureView";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import IPermission from "../../../core/interfaces/IPermission";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import {useEffect, useState} from "react";
import findCompanyDepartmentsRequest
  from "../../../core/api/requests/company/company-departments/findCompanyDepartmentsRequest";
import findCompanyDevicesRequest from "../../../core/api/requests/company/company-devices/findCompanyDevicesRequest";
import IDevice from "../../../core/interfaces/IDevice";
import {Button, Form, InputGroup} from "react-bootstrap";
import hasPermission from "../../../core/utils/hasPermission";
import Paginator from "../../../shared/common/Paginator";
import CompanyViewConnectionDeviceModal from "../../../shared/modals/CompanyViewConnectionDeviceModal";
import CompanyCreateDeviceModal from "../../../shared/modals/CompanyCreateDeviceModal";
import companyDeviceCreateRequest, {
  ICompanyDeviceCreateRequest
} from "../../../core/api/requests/company/company-devices/companyDeviceCreateRequest";
import companyDepartmentCreateRequest
  from "../../../core/api/requests/company/company-departments/companyDepartmentCreateRequest";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";
import companyDeviceRemoveRequest from "../../../core/api/requests/company/company-devices/companyDeviceRemoveRequest";

const permission: IPermission = {
  module: EModules.COMPANY_DEVICES,
  method: ECommonPermissions.FIND,
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();

  const [ data, setData ] = useState<IDevice[]>([])

  const [ count, setCount ] = useState<number>(0);
  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');

  // modal
  const [ show, setShow ] = useState<boolean>(false);

  const [ deviceConnection, setDeviceConnection ] = useState<IDevice | null>(null)

  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);

  const changePageAndFind = (page: number) => find(page)

  const find = async (
    pageByParam?: number,
  ) => {
    if (!selectedCompany.company) return;

    if (pageByParam) setPage(pageByParam)

    const response = await findCompanyDevicesRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
      selectedCompany.company,
    )

    if (response?.data) {
      setData(response.data?.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)
    }
  }

  useEffect(() => {
    find()
  }, [selectedCompany.company])

  useEffect(() => {
    setPage(1)
  }, [query])

  const remove = async (data: IDevice) => {
    if (!selectedCompany.company || !data.id) return;

    const response = await companyDeviceRemoveRequest(
      dispatch,
      {
        id: data.id,
      },
      selectedCompany.company,
    );

    if (response.statusCode === EHttpStatusCodes.OK) {
      sweetalert2.fire(
        `Excelente`,
        `Dispositivo ${data.name} eliminado exitosamente`,
        'success',
      ).then(() => find(1))
    } else {
      sweetalert2.fire(
        `Error`,
        response.data.message,
        'error',
      )
    }

    await find(1);
  }

  const create = async (data: ICompanyDeviceCreateRequest) => {
    if (!selectedCompany.company) return;

    const response = await companyDeviceCreateRequest(
      dispatch,
      data,
      selectedCompany.company,
    );

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Departamento ${data.name} creado exitosamente`,
        'success',
      ).then(() => find(1))
    } else {
      sweetalert2.fire(
        `Error`,
        response.data.message,
        'error',
      )
    }

    await find(1)
  }

  return <SecureView>
    <Card className={'col-12'}>
      <CardBody title={`Dispositivos ${count}`}>

        <CompanyViewConnectionDeviceModal
          onClose={() => setDeviceConnection(null)}
          device={deviceConnection}
        />

        <CompanyCreateDeviceModal
          show={show}
          onSubmit={create}
          onClose={handleClose}
        />

        <div className={'row mt-5'}>
          <div className={'col-9'}>
            <InputGroup>
              <Form.Control
                placeholder="Buscar por nombre..."
                aria-label="Buscar por nombre..."
                aria-describedby="Buscar por nombre..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(key) => key.key === 'Enter' && find(1)}
              />
              <Button
                variant="outline-primary"
                id="button-addon2"
                onClick={() => find(1)}
              >
                <i className={'ti-search'}></i> Buscar
              </Button>
            </InputGroup>
          </div>

          <div className={'col-3'}>
            <Button
              type={'button'}
              onClick={handleOpen}
              className="btn btn-info float-right"
            >Nuevo Dispositivo
            </Button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered m-t-30 table-hover contact-list">
            <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Departamento</th>
              <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            {data.map((device) => {
              return <tr key={device.id}>
                <td>{device.id}</td>
                <td>{device.name}</td>
                <td>{device.department.name}</td>
                <td>
                  <Button size={'sm'} variant={'info'} onClick={() => setDeviceConnection(device)}>
                    Conectar
                  </Button>
                  {hasPermission(permissions, { module: permission.module, method: ECommonPermissions.REMOVE }) &&
                      <Button className={'ml-2'} size={'sm'} variant={'danger'} onClick={() => remove(device)}>
                          <i className={'ti-trash'}></i> Eliminar
                      </Button>
                  }
                </td>
              </tr>
            })
            }
            </tbody>
          </table>

          <Paginator
            total={totalPages}
            page={page}
            goTo={changePageAndFind}
          />
        </div>

      </CardBody>
    </Card>
  </SecureView>
}
