enum ERoutes {
  HOME = '/',
  SCANNER = '/scanner',
  AUTH_LOGIN = '/auth/login',
  AUTH_LOGOUT = '/auth/logout',

  PRINT_TAG = '/printer/tag',

  AUTH_PASSWORD_RECOVERY_REQUEST = '/auth/password-recovery',

  VERIFY_PASSWORD_RECOVERY = '/verify/password-recovery',
  VERIFY_ACCOUNT_ACTIVATION = '/verify/account-activation',

  DASHBOARD = '/dashboard',
  // MY_PROFILE = '/me',

  USERS_LIST = '/users',
  USERS_VIEW = '/users/:id',
  USERS_CREATE = '/users/new',

  COMPANIES_LIST = '/companies',
  // COMPANIES_VIEW = '/companies/:id',
  COMPANIES_CREATE = '/companies/new',

  COMPANY_USERS_LIST = '/company/:cid/users',

  COMPANY_ROLES_LIST = '/company/:cid/roles',

  COMPANY_PRODUCTS_LIST = '/company/:cid/products',
  COMPANY_PRODUCTS_VIEW = '/company/:cid/products/:id',
  COMPANY_PRODUCTS_CREATE = '/company/:cid/products/new',

  COMPANY_ORDERS_LIST = '/company/:cid/orders',

  COMPANY_DEVICES_LIST = '/company/:cid/devices',

  COMPANY_DEPARTMENTS_LIST = '/company/:cid/departments',

  COMPANY_REPORTS = '/reports',

  COMPANY_STOCK_VIEW = '/company/:cid/stock',
  COMPANY_STOCK_NEW = '/company/:cid/stock/new',
  COMPANY_STOCK_VIEW_SUMMARY = '/company/:cid/stock/summary',
  COMPANY_STOCK_TRANSFERENCES_LIST = '/company/:cid/stock/transfers',
  COMPANY_STOCK_TRANSFERENCES_CREATE = '/company/:cid/stock/transfers/new',
  COMPANY_STOCK_TRANSFERENCES_VIEW = '/company/:cid/stock/transfers/:id',

}

export default ERoutes;
