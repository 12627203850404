import ICompanyModule from "../interfaces/ICompanyModule";
import IReducedCompany from "../interfaces/reduced/IReducedCompany";

export default function (
  company: IReducedCompany | undefined,
  name: string,
): ICompanyModule | null {
  if (!company) return null;
  const m = company.modules?.find((moTemp) => moTemp.name === name)
  if (!m) return null

  return m
}
