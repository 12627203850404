import SecureView from "../../../shared/common/SecureView";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import {Button, Form, InputGroup} from "react-bootstrap";
import hasPermission from "../../../core/utils/hasPermission";
import Paginator from "../../../shared/common/Paginator";
import ICompanyUser from "../../../core/interfaces/ICompanyUser";
import EHttpStatusCode from "../../../core/enums/EHttpStatusCodes";
import companyUserRemoveRequest from "../../../core/api/requests/company/company-users/companyUserRemoveRequest";
import assignCompanyUserRequest, {
  IAssignCompanyUserRequest
} from "../../../core/api/requests/company/company-users/assignCompanyUserRequest";
import companyUserUpdateRoleRequest, {
  ICompanyUserUpdateRoleRequest
} from "../../../core/api/requests/company/company-users/companyUserUpdateRoleRequest";
import IModule from "../../../core/interfaces/IModule";
import RCAlerts from "../../../core/utils/swal/RCAlerts";
import findCompanyRolesRequest from "../../../core/api/requests/company/company-roles/findCompanyRolesRequest";
import ICompanyRole from "../../../core/interfaces/ICompanyRole";
import CompanyCreateRoleModal from "../../../shared/modals/CompanyCreateRoleModal";
import companyRoleCreateRequest, {
  ICreateCompanyRoleInput
} from "../../../core/api/requests/company/company-roles/companyRoleCreateRequest";
import CompanyRoleUpdateRequest, {IUpdateCompanyRoleInput} from "../../../core/api/requests/company/company-roles/companyRoleUpdateRequest";
import CompanyModifyRoleModal from "../../../shared/modals/CompanyModifyRoleModal";
import companyRoleUpdateRequest from "../../../core/api/requests/company/company-roles/companyRoleUpdateRequest";

const m: IModule = {
  module: EModules.COMPANY_ROLES,
  method: ECommonPermissions.FIND,
  name: 'Roles',
  singularName: 'Rol',
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const [ module, setModule ] = useState<IModule>(m)

  const [ roles, setRoles ] = useState<ICompanyRole[]>([]);

  const [ count, setCount ] = useState<number>(0);

  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');

  // modal
  const [ show, setShow ] = useState<boolean>(false);
  const [ showUpdate, setShowUpdate ] = useState<string>('')

  const [ idToEdit, setIdToEdit ] = useState<string>('');
  const [ emailToEdit, setEmailToEdit ] = useState<string>('');
  const [ roleIdToEdit, setRoleIdToEdit ] = useState<string>('');

  const changePageAndFind = (page: number) => find(page)

  const find = async (
    pageByParam?: number,
  ) => {
    if (!selectedCompany.company) return;

    if (pageByParam) setPage(pageByParam)

    const response = await findCompanyRolesRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
      selectedCompany.company,
    )

    if (response?.data) {
      setRoles(response.data?.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)
    }
  }

  useEffect(() => {
    find()
  }, [selectedCompany.company])

  useEffect(() => {
    setPage(1)
  }, [query])

  const handleClose = () => {
    setIdToEdit('')
    setEmailToEdit('')
    setRoleIdToEdit('')

    setShow(false)
  };
  const handleOpen = () => setShow(true);

  const updateRole = (role: ICompanyRole) => {
    setShowUpdate(role.id)
  }

  const newRole = async (input: ICreateCompanyRoleInput) => {
    if (!selectedCompany.company) return

    const response = await companyRoleCreateRequest(
      dispatch,
      input,
      selectedCompany.company,
    )

    const a = new RCAlerts(module)
    a.created({
      response,
      successHttpCode: EHttpStatusCode.ACCEPTED,
    })

    find(1)
  }

  const updateRoleRequest = async (id: string, input: IUpdateCompanyRoleInput) => {
    if (!selectedCompany.company) return

    const response = await companyRoleUpdateRequest(
      dispatch,
      input,
      selectedCompany.company,
      id,
    )

    const a = new RCAlerts(module)
    a.update({
      response,
      successHttpCode: EHttpStatusCode.ACCEPTED,
    })

    find(1)
  }

  return <SecureView module={module} setModule={setModule}>
    <Card className={'col-12'}>
      <CardBody title={`${module.name} (${count})`}>
        <CompanyCreateRoleModal
          onSubmit={newRole}
          onClose={handleClose}
          show={show}
          module={module}
        />

        <CompanyModifyRoleModal
          onSubmit={updateRoleRequest}
          onClose={() => setShowUpdate('')}
          show={!!showUpdate}
          module={module}
          id={showUpdate}
        />

        <div className={'row mt-5'}>
          <div className={'col-9'}>
            <InputGroup>
              <Form.Control
                placeholder="Buscar por nombre..."
                aria-label="Buscar por nombre..."
                aria-describedby="Buscar por nombre..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(key) => key.key === 'Enter' && find(1)}
              />
              <Button
                variant="outline-primary"
                id="button-addon2"
                onClick={() => find(1)}
              >
                <i className={'ti-search'}></i> Buscar
              </Button>
            </InputGroup>
          </div>

          <div className={'col-3'}>
            <Button
              type={'button'}
              onClick={handleOpen}
              className="btn btn-info float-right"
            >Nuevo {module.singularName}
            </Button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered m-t-30 table-hover contact-list">
            <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            {roles.map((role) => {
              return <tr key={role.id}>
                <td>{role.id}</td>
                <td>{role.name}</td>
                <td>
                  {hasPermission(permissions, { module: module.module, method: ECommonPermissions.PARTIAL_UPDATE }) &&
                      <Button size={'sm'} className={'ml-2'} variant={'secondary'} onClick={() => updateRole(role)}>
                          <i className={'ti-pencil'}></i> Editar
                      </Button>
                  }
                </td>
              </tr>
            })
            }
            </tbody>
          </table>

          <Paginator
            total={totalPages}
            page={page}
            goTo={changePageAndFind}
          />
        </div>
      </CardBody>
    </Card>
  </SecureView>
}
