import SecureView from "../../../shared/common/SecureView";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import {useEffect, useState} from "react";
import findCompanyDepartmentsRequest
  from "../../../core/api/requests/company/company-departments/findCompanyDepartmentsRequest";
import {Button, Form, InputGroup} from "react-bootstrap";
import hasPermission from "../../../core/utils/hasPermission";
import Paginator from "../../../shared/common/Paginator";
import IDepartment from "../../../core/interfaces/IDepartment";
import companyDepartmentRemoveRequest
  from "../../../core/api/requests/company/company-departments/companyDepartmentRemoveRequest";
import EHttpStatusCode from "../../../core/enums/EHttpStatusCodes";
import CompanyCreateDepartmentModal from "../../../shared/modals/CompanyCreateDepartmentModal";
import companyDepartmentCreateRequest, {
  ICompanyUserRemoveRequest
} from "../../../core/api/requests/company/company-departments/companyDepartmentCreateRequest";
import IModule from "../../../core/interfaces/IModule";
import RCAlerts from "../../../core/utils/swal/RCAlerts";
import CompanyUpdateDepartmentModal from "../../../shared/modals/CompanyUpdateDepartmentModal";

const m: IModule = {
  module: EModules.COMPANY_DEPARTMENTS,
  method: ECommonPermissions.FIND,
  name: 'Departamentos',
  singularName: 'Departamento',
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();

  const [ module, setModule ] = useState<IModule>(m)

  const [ count, setCount ] = useState<number>(0);

  const [ departments, setDepartments ] = useState<IDepartment[]>([]);

  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');

  const [ show, setShow ] = useState<boolean>(false);
  const [ showUpdate, setShowUpdate ] = useState<string>('');

  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);

  const changePageAndFind = (page: number) => find(page)

  const find = async (
    pageByParam?: number,
  ) => {
    if (!selectedCompany.company) return;

    if (pageByParam) setPage(pageByParam)

    const response = await findCompanyDepartmentsRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
      selectedCompany.company,
    )

    if (response?.data) {
      setDepartments(response.data?.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)
    }
  }

  useEffect(() => {
    find()
  }, [selectedCompany.company])

  useEffect(() => {
    setPage(1)
  }, [query])

  const remove = (department: IDepartment) => {
    if (!selectedCompany.company || !department.id) return;

    const rcAlert = new RCAlerts(module)

    rcAlert.preDelete({
      async onAccept() {
        if (!selectedCompany.company || !department.id) return;

        const response = await companyDepartmentRemoveRequest(
          dispatch,
          {
            id: department.id,
          },
          selectedCompany.company,
        );

        rcAlert.delete({
          response,
          successHttpCode: EHttpStatusCode.OK,
          async onAccept() {
            await find(1);
          },
          async onCancel() {
            await find(1);
          }
        })

      },
      onCancel() {}
    })
  }

  const create = async (data: ICompanyUserRemoveRequest) => {
    if (!selectedCompany.company) return;

    const response = await companyDepartmentCreateRequest(
      dispatch,
      data,
      selectedCompany.company,
    );

    const s = new RCAlerts(module)
    s.created({
      response,
      successHttpCode: EHttpStatusCode.ACCEPTED,
      onAccept() {
        find(1)
      },
      onCancel() {
        find(1)
      }
    })
  }

  return <SecureView module={module} setModule={setModule}>
    <Card className={'col-12'}>
      <CardBody title={`${module.name} (${count})`}>

        <CompanyCreateDepartmentModal
          show={show}
          onSubmit={create}
          onClose={handleClose}
          module={module}
        />

        <CompanyUpdateDepartmentModal
          show={!!showUpdate}
          onSubmit={() => {
            find(1)
            setShowUpdate('')
          }}
          onClose={() => {
            find(1)
            setShowUpdate('')
          }}
          module={module}
          department={showUpdate}
        />

        <div className={'row mt-5'}>
          <div className={'col-9'}>
            <InputGroup>
              <Form.Control
                placeholder="Buscar por nombre......"
                aria-label="Buscar por nombre......"
                aria-describedby="Buscar por nombre......"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(key) => key.key === 'Enter' && find(1)}
              />
              <Button
                variant="outline-primary"
                id="button-addon2"
                onClick={() => find(1)}
              >
                <i className={'ti-search'}></i> Buscar
              </Button>
            </InputGroup>
          </div>

          <div className={'col-3'}>
            {hasPermission(permissions, { module: module.module, method: ECommonPermissions.CREATE }) &&
              <Button
                type={'button'}
                onClick={handleOpen}
                className="btn btn-info float-right"
              >Nuevo {module.singularName}
              </Button>
            }
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered m-t-30 table-hover contact-list">
            <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            {departments.map((department) => {
              return <tr key={department.id}>
                <td>{department.id}</td>
                <td>{department.name}</td>
                <td>
                  {hasPermission(permissions, { module: (module?.module || ''), method: ECommonPermissions.UPDATE }) &&
                    <Button size={'sm'} variant={'secondary'} onClick={() => setShowUpdate(department.id || '')}>
                      <i className={'ti-pencil'}></i> Modificar
                    </Button>
                  }

                  {hasPermission(permissions, { module: (module?.module || ''), method: ECommonPermissions.REMOVE }) &&
                      <Button className={'ml-2'} size={'sm'} variant={'danger'} onClick={() => remove(department)}>
                          <i className={'ti-trash'}></i> Eliminar
                      </Button>
                  }
                </td>
              </tr>
            })
            }
            </tbody>
          </table>

          <Paginator
            total={totalPages}
            page={page}
            goTo={changePageAndFind}
          />
        </div>
      </CardBody>
    </Card>
  </SecureView>
}
