import {Button, Col, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {read} from "node:fs";

export interface IComponent {
  onChangeWeight(weight: number): void
  onDisconnect?(): void;
  onConnect?(): void;
  disconnect?: boolean;
}

let globalPort: SerialPort | null = null
let keepReading = true;
let reader: ReadableStreamDefaultReader<any>;

export default function (props: IComponent) {
  const [ connected, setConnected ] = useState<boolean>(false)

  const [ lastReadWeight, setLastReadWeight ] = useState<number>(0)

  const [ automaticRead, setAutomaticRead ] = useState<NodeJS.Timer>()

  const setPort = async () => {
    globalPort = await navigator.serial.requestPort();
    keepReading = true

    await globalPort.open({
      baudRate: 9600,
      parity: 'none',
      dataBits: 8,
      stopBits: 1
    })

    const pi = globalPort.getInfo()

    setConnected(true)
  }

  const connect = async () => {
    await setPort()
  }

  const disconnect = async () => {
    if (!globalPort) return
    console.log('closing port...')

    window.location.reload()

    // keepReading = false
    // if (reader) {
    //   reader.releaseLock()
    // }
    //
    // await globalPort.close();
    //
    // globalPort = null
    // setConnected(false)
  }

  useEffect(() => {
    if (props.disconnect) {
      disconnect()
    }
  }, [props.disconnect]);

  const extractWeight = (weight: string): number | null => {
    if (weight.length === 10) {
      return 0 // is negative
    }

    if (weight.length < 9) return null

    const w = weight.substring(1,7)
    const n = parseInt(w)

    if (isNaN(n)) return 0
    else return n
  }

  const setWeight = (w: string) => {
    const weight = extractWeight(w)
    console.log('weight', w, weight)
    if (weight === null) return

    setLastReadWeight(weight)
    props.onChangeWeight(weight)
  }

  useEffect(() => {
    if (connected) {
      startRead()
    }
  }, [connected]);

  const manualRead = async () => {
    if (automaticRead || !connected) return

    await requestWeight()
  }

  const startAutomaticRead = async () => {
    if (automaticRead || !connected) return

    const i = setInterval(() => {
      requestWeight()
    }, 1000)

    setAutomaticRead(i)
  }

  const stopAutomaticRead = async () => {
    if (!automaticRead || !connected) return

    clearInterval(automaticRead)
    setAutomaticRead(undefined)
  }

  const requestWeight = async () => {
    if (!globalPort || !globalPort.writable) return
    console.log('requesting weight...')

    const commandHex = "05"
    // @ts-ignore
    const commandBuffer = new Uint8Array(commandHex.match(/.{1,2}/g)?.map(byte => parseInt(byte, 16)),).buffer

    const writter = globalPort.writable.getWriter();

    if (!writter) return;

    await writter.write(commandBuffer)

    await writter.close()
  }

  const startRead = async () => {
    if (!globalPort) return

    while (globalPort.readable && keepReading) {
      reader = globalPort.readable.getReader()

      try {
        while (true) {
          const { value, done } = await reader.read();

          if (done) {
            break;
          }

          const text = String.fromCharCode.apply(null, value)
          setWeight(text)
        }
      } catch (err: any) {
        console.log('read error', err)
      } finally {
        reader.releaseLock()
      }
    }

    await disconnect()
  }

  return <Row>
    <Col sm={12}>
      {connected ?
        <Button variant="danger" type="button" className={'mt-3'} onClick={disconnect}>
          Desconectar balanza
        </Button>
        :
        <Button variant="success" type="button" className={'mt-3'} onClick={connect}>
          <i className='ti-stats-up'></i> Conectar balanza
        </Button>
      }
    </Col>

    {(connected && !automaticRead) &&
      <Col sm={12}>
        <Button variant="warning" type="button" className={'mt-3'} onClick={manualRead}>
          Leer
        </Button>

        <Button variant="info" type="button" className={'mt-3 ml-3'} onClick={startAutomaticRead}>
          Lectura automatica
        </Button>
      </Col>
    }

    {(connected && automaticRead) &&
      <Col sm={12}>
        <Button variant="danger" type="button" className={'mt-3'} onClick={stopAutomaticRead}>
          Detener lectura automatica
        </Button>
      </Col>
    }

  </Row>
}
