import {Dispatch} from "redux";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";

export interface ICompanyOrderProductRequest {
  productId: string;
  count: number;
  notes?: string;
}

export interface ICompanyOrderCreateRequest {
  products: ICompanyOrderProductRequest[];
  orderReferenceId?: string;
  client: {
    name?: string;
    email?: string;
  }
  status: string;
}

export default async function (
  dispatch: Dispatch,
  input: ICompanyOrderCreateRequest,
  company: IReducedCompany,
): Promise<IResponse> {
  dispatch(startLoading())

  const request = new RACARequest('companyOrders', 'create')
    .setData(input).setPathValues('COMPANY_ID', company.id);

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading())

  return response;
}
