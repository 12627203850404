import {Dispatch} from "redux";
import IBasicFilters from "../../../../interfaces/IBasicFilters";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";

export default async function (
  dispatch: Dispatch,
  filters: IBasicFilters,
  company: IReducedCompany,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('companyDepartments', 'list')
    .setQueryParameters(filters).setPathValues('COMPANY_ID', company.id)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
