import {Dispatch} from "redux";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";

export default async (
  dispatch: Dispatch,
  company: IReducedCompany,
): Promise<IResponse> => {
  dispatch(startLoading())

  const request = new RACARequest('companyReports', 'create')
    .setPathValues('COMPANY_ID', company.id)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading())

  return response;
}
