import {useEffect, useState} from "react";
import {Badge, Button, Form, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import IModule from "../../core/interfaces/IModule";
import IProductUnit from "../../core/interfaces/IProductUnit";
import findByIdCompanyProductsUnitRequest
  from "../../core/api/requests/company/company-products/findByIdCompanyProductsUnitRequest";
import {useParams} from "react-router-dom";
import moment from "moment";
import ISubdepartment from "../../core/interfaces/ISubdepartment";
import loadModuleInformation from "../../core/utils/loadModuleInformation";
import EModules from "../../core/enums/EModules";
import IDepartment from "../../core/interfaces/IDepartment";
import ICompanyModule from "../../core/interfaces/ICompanyModule";
import findCompanySubdepartmentsRequest
  from "../../core/api/requests/company/company-departments/subdepartments/findCompanySubdepartmentsRequest";
import CapitalizeAndTransform from "../../core/utils/CapitalizeAndTransform";
import IScanner from "../../core/interfaces/IScanner";
import ScannerForModals from "../common/ScannerForModals";

export interface ICompanyCreateDepartmentModal {
  onSubmit(product: IProductUnit, subDepartmentId: string): void;
  onClose(): void;
  show: boolean;
  module: IModule;
  department?: IDepartment;
  subdepartmentId?: string;
  code?: string;
  scanner: IScanner;
}

export default function (data: ICompanyCreateDepartmentModal) {
  const dispatch = useDispatch()
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)

  const params = useParams()

  const [ subdepartment, setSubdepartment ] = useState<ISubdepartment>()
  const [ subdepartments, setSubdepartments ] = useState<ISubdepartment[]>([])
  const [ departmentModule, setDepartmentModule ] = useState<ICompanyModule>()

  const [ code, setCode ] = useState<string>('')

  const findProductById = async (id: string) => {
    if (!selectedCompany?.company || !subdepartment?.id) return

    const response = await findByIdCompanyProductsUnitRequest(
      dispatch,
      selectedCompany.company,
      id,
    )

    if (response.data) {
      data.onSubmit(response.data, subdepartment?.id)
      setCode('')
    }
  }

  const loadModules = () => {
    const department = loadModuleInformation(selectedCompany?.company, EModules.COMPANY_DEPARTMENTS)
    if (department) {
      setDepartmentModule(department)
    }
  }

  const selectSubdepartment = (id: string) => {
    const d = subdepartments.find((dt) => dt.id === id)
    if (!d) {
      setSubdepartment(undefined)
      return
    }

    setSubdepartment(d)
  }

  useEffect(() => {
    if (data.department?.id) {
      getSubdepartments(data.department.id)
    }
  }, [data.department]);

  const getSubdepartments = async (id: string) => {
    if (!selectedCompany.company) return

    const response = await findCompanySubdepartmentsRequest(
      dispatch,
      selectedCompany.company,
      id,
    )

    if (response.data) {
      setSubdepartments(response.data.data)
    }
  }

  useEffect(() => {
    if (code) {
      findProductById(code)
    }
  }, [code]);

  useEffect(() => {
    loadModules()

    return () => {
      setCode('')
    }
  }, [data.show])

  useEffect(() => {
    console.log(data.scanner.code)
    if (data.scanner?.code && data.show) {
      setCode(data.scanner.code || '')
    }
  }, [data.scanner.code]);

  useEffect(() => {
    if (data.subdepartmentId) {
      selectSubdepartment(data.subdepartmentId)
    }
  }, [subdepartments, data.subdepartmentId]);

  const close = () => {
    data.onClose()
  }

  return <Modal show={data.show} onHide={data.onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Recibir Producto a {data.department?.name}</Modal.Title>'
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3">
          <label>{CapitalizeAndTransform(departmentModule?.settings.private.subdepartmentName || '')}</label>
          <select disabled={!data.department} className={'custom-select'} value={subdepartment?.id}
                  onChange={(e) => selectSubdepartment(e.target.value)}>
            <option>Selecciona...</option>
            {subdepartments.map((st) => {
              return <option key={st.id} value={st.id}>{st.name}</option>
            })
            }
          </select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="fefc3907-cf5b-4412-8779-519db8bf4d6e"
            autoFocus
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <ScannerForModals scanner={data.scanner} />

      <Button variant="secondary" onClick={close}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>

}
