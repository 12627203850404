import axios from "axios";

const getVersion = async (): Promise<string> => {
  try {
    const racaVersion = await axios.get('/version.raca')

    if (racaVersion.data) {
      return racaVersion.data.trim()
    } else return ''
  } catch (err: any) {
    return 'n/a'
  }
}

export default getVersion;
