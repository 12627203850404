import {Link, useNavigate} from "react-router-dom";
import ERoutes from "../../core/enums/ERoutes";
import logo from '../styles/images/logo.png';
import {useEffect, useState} from "react";
import IMenuItem from "../../core/interfaces/IMenuItem";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import menuList from "./menuList";
import hasPermission from "../../core/utils/hasPermission";
import {setCompany} from "../../core/store/slices/selectedCompanySlice";
import {endLoading, startLoading} from "../../core/store/slices/loadingSlice";
import getMyProfileRequest from "../../core/api/requests/me/getMyProfileRequest";
import getMyCompanyProfileRequest from "../../core/api/requests/me/getMyCompanyProfileRequest";
import getMyCompaniesRequest from "../../core/api/requests/me/getMyCompaniesRequest";
import getFrontendUtilitiesRequest from "../../core/api/requests/utils/getFrontUtilitiesRequest";
import {Badge} from "react-bootstrap";
import getVersion from "../../version";
import capitalizeFirstLetter from "../../core/utils/CapitalizeFirstLetter";
import EColors from "../../core/enums/EColors";
import CapitalizeFirstLetter from "../../core/utils/CapitalizeFirstLetter";

export default function Sidebar() {
  const dispatch = useDispatch()
  const permissions = useSelector((state: RootState) => state.permissions);
  const availableCompanies = useSelector((state: RootState) => state.availableCompanies);
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany);
  const frontendUtilities = useSelector((state: RootState) => state.frontendUtilities);
  const userMe = useSelector((state: RootState) => state.userMe)

  const [ version, setVersion ] = useState<string>('')
  const [ menu, setMenu ] = useState<IMenuItem[]>([])

  const selectCompany = async (e: any) => {
    e.preventDefault()

    if (!e.target.value) return

    const company = availableCompanies.find((ac) => ac.id === e.target.value);
    if (!company) return

    dispatch(
      setCompany(company)
    )

    dispatch(startLoading())
    await getMyCompanyProfile(company.id)
    dispatch(endLoading())
  }

  const validateMenuByPermissions = () => {
    const menuTemp: IMenuItem[] = []
    menuList.forEach((m) => {
      if (m.forCompany) {
        if (!selectedCompany?.company) return
        if (permissions.length === 0) return

        if(!!m.permission) {
          if (!selectedCompany.company?.modules) return;
          const findModule = selectedCompany.company.modules.find((module) => module.name === m.permission?.module)
          if (!findModule) return

          if (findModule.settings) {
            if (findModule.settings.private?.moduleName) {
              m.name = capitalizeFirstLetter(findModule.settings.private?.moduleName)
            }
          }
        }

        if (!m.permission) {
          menuTemp.push(m)
        } else if (hasPermission(permissions, m.permission)) {
          menuTemp.push(m)
        }
      } else {
        if (!userMe?.mainRole) return
        if (!userMe.mainRole.permissions) return;
        if (userMe.mainRole.permissions.length === 0) return;

        if (!m.permission) {
          menuTemp.push(m)
        } else if (hasPermission(userMe.mainRole.permissions, m.permission)) {
          menuTemp.push(m)
        }
      }

    })

    setMenu(menuTemp)
  }

  const getMyProfile = async () => await getMyProfileRequest(dispatch);

  const getMyCompanyProfile = async (id: string) => {
    await getMyCompanyProfileRequest(dispatch, id)
  };

  const getMyCompanies = async () => await getMyCompaniesRequest(dispatch);

  const getFrontUtilities = async () => await getFrontendUtilitiesRequest(dispatch);

  const initialRequests = async () => {
    dispatch(startLoading())
    await Promise.all([
      selectedCompany.company ? getMyCompanyProfile(selectedCompany.company.id) : getMyProfile(),
      getMyCompanies(),
      getFrontUtilities(),
    ])
    dispatch(endLoading())
  }

  const chargeVersion = async () => {
    const v = await getVersion()
    setVersion(v);
  }

  useEffect(() => {
    initialRequests()
    chargeVersion();
  }, [])

  useEffect(() => validateMenuByPermissions(), [permissions, selectedCompany.company?.modules])

  return <>
    <div className={"d-md-none show"} id={'mobile-menu'}>
      <div className={'mobile-header'}>
        <div className={'company-information'}>
          <div className={'logo-container'}>
            <img src={logo}/>
          </div>
          <div className={'badge-version'}>
            <Badge bg="dark" style={{color: EColors.WHITE}}>{version}</Badge>
          </div>
        </div>

        {userMe?.user &&
          <div className={'user-profile'}>
            <span className="hide-menu">{userMe?.user.name} {userMe.user.lastName}</span>
            <small>{userMe.user.email}</small>
            <small>{CapitalizeFirstLetter(userMe.user.role.name)}</small>
          </div>
        }
      </div>

    </div>

    <aside className="left-sidebar pt-2 d-none d-md-flex " id={"sidebar"}>
      <div className={'aside-container '}>
        <div className='aside-logo'>
          <div className={'company-information'}>
            <div className={'logo-container'}>
              <img src={logo}/>
            </div>
            <div className={'badge-version'}>
              <Badge bg="dark" style={{color: EColors.WHITE}}>{version}</Badge>
            </div>
          </div>

          {userMe?.user &&
            <div className={'user-profile mt-4'}>
              <span className="hide-menu">{userMe?.user.name} {userMe.user.lastName}</span>
              <small>{userMe.user.email}</small>
              <small>{CapitalizeFirstLetter(userMe.user.role.name)}</small>
            </div>
          }

          <hr/>
          {availableCompanies.length ?
            <div className={'form-group company-selector'}>
              <label>Compañia seleccionada</label>
              <select className={'custom-select'} onChange={selectCompany} value={selectedCompany?.company?.id}
                      defaultValue={'-1'}>
                <option value={'-1'}>Seleccione...</option>
                {availableCompanies.map((ac) => <option
                    key={ac.id}
                    value={ac.id}
                  > [{ac.code}] - {ac.name}</option>
                )}
              </select>
            </div>
            :
            <div className={'alert alert-warning'}>No tienes compañias asignadas</div>
          }

          <hr/>
        </div>

        <nav className="sidebar-nav">
          <ul className="menu-buttons">
            {menu.map((m) =>
              <li key={`${m.name}${Date.now()}`}>
                <Link to={m.redirectTo.replaceAll(':cid', selectedCompany?.company?.id || '')}
                      style={{color: m.textColor || frontendUtilities.colors.primary}}><i
                  style={{color: m.iconColor || frontendUtilities.colors.primary}} className={m.icon}></i> {m.name}
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>

      <div className="sidebar-nav">
        <ul className={"buttons-footer"}>
          <li>
            <Link to={ERoutes.AUTH_LOGOUT} style={{color: frontendUtilities.colors.primary}}><i
              style={{color: frontendUtilities.colors.primary}} className="icon-logout"></i> Logout</Link>
          </li>
        </ul>
      </div>
    </aside>
  </>
}
