import SecureView from "../../../shared/common/SecureView";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import IModule from "../../../core/interfaces/IModule";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import {useEffect, useState} from "react";
import {Button, Form, Row} from "react-bootstrap";
import IProduct from "../../../core/interfaces/IProduct";
import {useNavigate, useParams} from "react-router-dom";
import findByIdCompanyProductsRequest
  from "../../../core/api/requests/company/company-products/findByIdCompanyProductsRequest";
import getCompanyStockByProduct from "../../../core/api/requests/company/company-stock/getCompanyStockByProduct";
import IProductUnit from "../../../core/interfaces/IProductUnit";
import Paginator from "../../../shared/common/Paginator";
import moment from "moment";
import ERoutes from "../../../core/enums/ERoutes";
import updateCompanyProductRequest
  from "../../../core/api/requests/company/company-products/updateCompanyProductRequest";
import RCAlerts from "../../../core/utils/swal/RCAlerts";
import EHttpStatusCode from "../../../core/enums/EHttpStatusCodes";
import {setScanner} from "../../../core/store/slices/scannerSlice";

const m: IModule = {
  module: EModules.COMPANY_PRODUCTS,
  method: ECommonPermissions.FIND_BY_ID,
  name: 'Productos',
  singularName: 'Producto',
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const [ module, setModule ] = useState<IModule>(m)
  const params = useParams()
  const futils = useSelector((state: RootState) => state.frontendUtilities);
  const navigator = useNavigate();

  const [ product, setProduct ] = useState<IProduct>()
  const [ productUnit, setProductUnit ] = useState<IProductUnit[]>()

  const [ count, setCount ] = useState<number>(0);
  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');


  const getStock = async (pageByParam?: number) => {
    if (!selectedCompany.company || !product?.id) return;
    if (pageByParam) setPage(pageByParam)

    const response = await getCompanyStockByProduct(
      dispatch,
      product.id,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
      selectedCompany.company,
    )

    if (response?.data) {
      setProductUnit(response.data.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)
    }
  }


  const changePageStockAndFind = (page: number) => getStock(page)

  const getProduct = async (id: string) => {
    if (!selectedCompany.company) return

    const response = await findByIdCompanyProductsRequest(
      dispatch,
      id,
      selectedCompany.company,
    )

    if (response.data) {
      setProduct(response.data)
    }
  }

  useEffect(() => {
    if (product?.id) {
      getStock(1)
    }
  }, [product?.id]);

  const setValue = (field: string, value: string) => {
    if (!product) return;
    const temp = product;

    switch (field) {
      case 'sku':
        temp.sku = value
        break;
      case 'name':
        temp.name = value
        break;
      case 'measurementUnit':
        temp.measurementUnit = value
        break;
      case 'presentationMeasurementUnit':
        temp.presentationMeasurementUnit = value
        break;
      case 'presentationValue':
        temp.presentationValue = parseInt(value) || 0
        break;
      case 'conversionRate':
        temp.conversionRate = parseInt(value) || 0
        break;
      default:
        return
    }

    setProduct({ ...temp })
  }

  useEffect(() => {
    if (params.id) {
      getProduct(params.id)
    }
  }, []);

  const save = async () => {
    if (!selectedCompany.company || !product?.id) return

    const response = await updateCompanyProductRequest(
      dispatch,
      {
        sku: product?.sku,
        name: product?.name,
        description: product?.description,
        measurementUnit: product?.measurementUnit,
        presentationMeasurementUnit: product?.presentationMeasurementUnit,
        presentationValue: product?.presentationValue,
        conversionRate: product?.conversionRate,
      },
      selectedCompany.company,
      product.id,
    )

    const a = new RCAlerts(module)
    a.update({
      response,
      successHttpCode: EHttpStatusCode.ACCEPTED,
      onAccept() {
        if (product.id) {
          getProduct(product.id)
        }
      }
    })

  }

  const goBack = () => navigator(ERoutes.COMPANY_PRODUCTS_LIST)

  const seeQR = (pu: IProductUnit) => {
    const url = pu.id

    dispatch(setScanner({
      showModal: true,
      sessionId: url,
    }))
  }

  return <SecureView module={module} setModule={setModule}>
    <Card className={'col-12'}>
      <CardBody title={'Product'}>
        <Form>
          <Row>
            <Form.Group className="col-3">
              <Form.Label>ID</Form.Label>
              <Form.Control
                disabled
                type="text"
                value={product?.id}
              />
            </Form.Group>
            <Form.Group className="col-3">
              <Form.Label>SKU</Form.Label>
              <Form.Control
                type="text"
                placeholder="Código SKU"
                value={product?.sku}
                onChange={(e) => setValue('sku', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-6">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                value={product?.name}
                onChange={(e) => setValue('name', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="col-3 mt-3">
              <Form.Label>Unidad de medida</Form.Label>
              <select className={'custom-select'} value={product?.measurementUnit} onChange={(e) => setValue('measurementUnit', e.target.value)}>
                <option value={'-1'}>Selecciona...</option>
                {futils?.futils?.measurementUnits.map((dpt) => <option key={dpt} value={dpt}>{dpt}</option>)
                }
              </select>
            </Form.Group>

            <Form.Group className="col-3 mt-3">
              <Form.Label>Unidad de presentación</Form.Label>
              <select className={'custom-select'} value={product?.presentationMeasurementUnit} onChange={(e) => setValue('presentationMeasurementUnit', e.target.value)}>
                <option value={'-1'}>Selecciona...</option>
                {futils?.futils?.measurementUnits.map((dpt) => <option key={dpt} value={dpt}>{dpt}</option>)
                }
              </select>
            </Form.Group>

            <Form.Group className="col-3 mt-3">
              <Form.Label>Valor de presentación</Form.Label>
              <Form.Control
                type="text"
                placeholder="Valor de presentación"
                value={product?.presentationValue}
                onChange={(e) => setValue('presentationValue', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="col-3 mt-3">
              <Form.Label>Tasa de conversión</Form.Label>
              <Form.Control
                type="text"
                placeholder="Valor de presentación"
                value={product?.conversionRate}
                onChange={(e) => setValue('conversionRate', e.target.value)}
              />
            </Form.Group>
          </Row>

          <Button variant="success" type="button" onClick={save} className={'mt-3'}>
            <i className='ti-save'></i> Guardar
          </Button>

          <Button variant="secondary" type="button" onClick={() => goBack()} className={'mt-3 ml-2'}>
            Cancelar
          </Button>
        </Form>
      </CardBody>
    </Card>

    <Card className={'col-12'}>
      <CardBody title={`Stock (${count})`}>
        <div className="table-responsive">
          <table className="table table-bordered m-t-30 table-hover contact-list">
            <thead>
            <tr>
              <th>ID</th>
              <th>Fecha</th>
              <th>Cantidad</th>
              <th>Ultimo movimiento</th>
              <th>Movimientos</th>
              <th>Ubicación actual</th>
              <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            {productUnit?.map((pu) => {
              return <tr key={pu.id}>
                <td>{pu.id}</td>
                <td>{moment(pu.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                <td>{pu.amount} {pu.measurementUnit}</td>
                <td>{pu.updateContext.fromUser} ({moment(pu.updateContext.date).format('DD/MM/YYYY HH:mm:ss')})</td>
                <td>{pu.historic.length}</td>
                <td>{pu.location.department.name} - {pu.location.subDepartment?.name}</td>
                <td>
                  <Button size={'sm'} variant={'light'} onClick={() => seeQR(pu)}>
                    <i className={'mdi mdi-qrcode'}></i>
                  </Button>
                </td>
              </tr>
            })
            }
            </tbody>
          </table>

          <Paginator
            total={totalPages}
            page={page}
            goTo={changePageStockAndFind}
          />
        </div>
      </CardBody>
    </Card>
  </SecureView>
}
