import {Dispatch} from "redux";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";

export interface ICreateCompanyProductRequest {
  name?: string;
  description?: string;
  departmentId?: string;
  price?: {
    amount?: number;
    currency?: string;
  },
  sku?: string;
  category?: string;
  stock?: number;
  additional?: any;
  measurementUnit?: string;
  status?: string;
}

export default async function (
  dispatch: Dispatch,
  input: ICreateCompanyProductRequest,
  company: IReducedCompany,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('companyProducts', 'create')
    .setData(input).setPathValues("COMPANY_ID", company.id)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());
  return response;
}
