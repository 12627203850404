import {Col} from "react-bootstrap";

export interface IComponent {
  children: any
  color?: string;
  onClick(): void;
}

export default function (props: IComponent) {
  return <div className={'col-3'}>
    <button onClick={props.onClick} className={`btn col-12 btn-${props.color || 'info'} p-4`}>
      {props.children}
    </button>
  </div>
}
