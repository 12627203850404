import SecureView from "../../shared/common/SecureView";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Alert, Button, Form, Row} from "react-bootstrap";
import {RootState} from "../../core/store/store";
import {setCompany} from "../../core/store/slices/selectedCompanySlice";
import {useEffect, useState} from "react";
import createCompanyRequest, {ICreateCompanyRequest} from "../../core/api/requests/companies/createCompanyRequest";
import ERoutes from "../../core/enums/ERoutes";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";
import IModule from "../../core/interfaces/IModule";

const m: IModule = {
  module: EModules.COMPANIES,
  method: ECommonPermissions.CREATE,
  name: 'Compañias',
  singularName: 'Compañia',
}

export default function () {
  const userMe = useSelector((state: RootState) => state.userMe)

  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [ module, setModule ] = useState<IModule>(m)

  const [ company, setCompany ] = useState<ICreateCompanyRequest>({})

  const [ error, setError ] = useState<string>('')

  const goBack = () => navigator(ERoutes.COMPANIES_LIST)

  const create = async () => {
    if (!userMe.user) return

    const response = await createCompanyRequest(
      dispatch,
      {
        ...company,
        owner: userMe.user.email,
      }
    )

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Compañia ${company.code} creada exitosamente`,
        'success',
      ).then(() => goBack())
    } else {
      sweetalert2.fire(
        `Error`,
        response.data.message,
        'error',
      )
    }
  }

  const setValue = (
    field: string,
    value: any,
  ) => {
    if (field === 'code') {
      value = value.toUpperCase()
    }

    setCompany((prevState) => {
      return {
        ...prevState,
        [field]: value,
      }
    });
  }

  useEffect(() => {
    if (company?.code && company.code.length !== 6) {
      setError('el código de la tienda debe tener 6 caracteres')
    } else {
      setError('')
    }
  }, [company])

  return <SecureView module={module} setModule={setModule} forAdmins>
    <Card className={'col-12'}>
      <CardBody title={`Nueva ${module.singularName}`}>
        <Form>
          <Row>
            <Form.Group className="mb-3 col-4">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Compañia John"
                value={company.name}
                onChange={(e) => setValue('name', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3 col-4">
              <Form.Label>Código unico (6 caracteres)</Form.Label>
              <Form.Control
                type="text"
                placeholder="TDAJHN"
                maxLength={6}
                value={company.code}
                onChange={(e) => setValue('code', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3 col-4">
              <Form.Label>Owner</Form.Label>
              <Form.Control
                type="text"
                placeholder="info@racasf.com"
                disabled
                value={userMe?.user?.email}
              />
            </Form.Group>
          </Row>
        </Form>

        {error ?
          <Alert>{error}</Alert>
          :
          <Button variant="info" type="button" onClick={create} className={'mt-3'}>
            <i className='ti-save'></i> Crear
          </Button>
        }
        <Button variant="secondary" type="button" onClick={() => goBack} className={'mt-3 ml-2'}>
          Cancelar
        </Button>
      </CardBody>
    </Card>
  </SecureView>
}
