import {Dispatch} from "redux";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";

export interface ICreateCompanyProductRequest {
  name?: string;
  description?: string;
  sku?: string;
  measurementUnit?: string;
  presentationMeasurementUnit?: string;
  presentationValue?: number;
  conversionRate?: number;
}

export default async function (
  dispatch: Dispatch,
  input: ICreateCompanyProductRequest,
  company: IReducedCompany,
  id: string,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('companyProducts', 'update')
    .setData({
      action: 'basicInformation',
      data: input
    }).setPathValues("COMPANY_ID", company.id)
    .setPathValues('ID', id)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());
  return response;
}
