import {Pagination} from "react-bootstrap";
import {useEffect, useState} from "react";

export interface IPaginator {
  total: number;
  page: number;
  goTo(page: number): void;
}

export default function Paginator(data: IPaginator) {
  const [ items, setItems ] = useState<number[]>([])

  const setPagiantorItems = () => {
    const tempItems = []

    // previous
    if (data.page > 1) {
      tempItems.push(data.page - 1);
    }

    // current
    tempItems.push(data.page)

    // next
    if (data.page < data.total) {
      tempItems.push(data.page + 1)
    }

    setItems(tempItems)
  }

  useEffect(() => setPagiantorItems(), [])
  useEffect(() => setPagiantorItems(), [data])

  return (
    <Pagination color={'success'} size={'sm'} >
      {data.page > 1 &&
          <Pagination.Prev onClick={() => data.goTo(data.page - 1)} />
      }

      {items.map((item) => {
        return <Pagination.Item
          key={`${item}${Date.now()}`}
          activeLabel=''
          active={data.page === item}
          onClick={() => data.goTo(item)}
        >{item}</Pagination.Item>
      })
      }

      {data.page < (data.total - 2) &&
          <Pagination.Ellipsis/>
      }

      {data.page < (data.total - 1) &&
          <Pagination.Item onClick={() => data.goTo(data.total)}>{data.total}</Pagination.Item>
      }

      {data.page < data.total &&
        <Pagination.Next onClick={() => data.goTo(data.page + 1)} />
      }
    </Pagination>
  )
}
