import IDevice from "../../core/interfaces/IDevice";
import IReducedCompany from "../../core/interfaces/reduced/IReducedCompany";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {Button, Modal, Table} from "react-bootstrap";

export interface ICompanyViewConnectionModal {
  device: IDevice | null;
  onClose(): void;
}

export default function (data: ICompanyViewConnectionModal) {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)

  return <Modal show={!!data.device} onHide={data.onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Como conectar al dispositivo</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className={'container-fluid'}>
        <div className={'row'}>
          <div className={'col-12'}>
            <ol>
              <li>Abrir el sitio de la comandera</li>
              <li>configurar el ID del dispositivo <br /><strong>({data.device?.id})</strong></li>
              <li>configurar la clave del dispositivo <br /><strong>({data.device?.secretKey})</strong></li>
              <li>configurar el codigo de compañia <br /><strong>({selectedCompany?.company?.id})</strong></li>
              <li>presionar en conectar y aguardar a que aparezca como conectado</li>
            </ol>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
}
