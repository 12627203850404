import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {logout} from "../../../core/store/slices/sessionSlice";
import {useNavigate} from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes";

export default function Logout() {
  const dispatch = useDispatch();
  const navigator = useNavigate()

  useEffect(() => {
    dispatch(logout())

    navigator(ERoutes.HOME)
  }, [])

  return <div></div>
}
