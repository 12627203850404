import SecureView from "../../../shared/common/SecureView";
import IModule from "../../../core/interfaces/IModule";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import {useEffect, useState} from "react";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import ISubdepartment from "../../../core/interfaces/ISubdepartment";
import ICompanyModule from "../../../core/interfaces/ICompanyModule";
import IDepartment from "../../../core/interfaces/IDepartment";
import {useNavigate} from "react-router-dom";
import loadModuleInformation from "../../../core/utils/loadModuleInformation";
import ERoutes from "../../../core/enums/ERoutes";
import findCompanyDepartmentsRequest
  from "../../../core/api/requests/company/company-departments/findCompanyDepartmentsRequest";
import {setDepartments} from "../../../core/store/slices/companyCommons";
import findCompanySubdepartmentsRequest
  from "../../../core/api/requests/company/company-departments/subdepartments/findCompanySubdepartmentsRequest";
import RCAlerts from "../../../core/utils/swal/RCAlerts";
import transformToSingular from "../../../core/utils/transformToSingular";
import createCompanyProductStockRequest
  from "../../../core/api/requests/company/company-stock/createCompanyProductStockRequest";
import EHttpStatusCode from "../../../core/enums/EHttpStatusCodes";
import createCompanyStockTransferRequest
  from "../../../core/api/requests/company/company-stock/createCompanyStockTransferRequest";
import {Button, Col, Row} from "react-bootstrap";
import CapitalizeAndTransform from "../../../core/utils/CapitalizeAndTransform";

const m: IModule = {
  module: EModules.COMPANY_STOCK,
  method: ECommonPermissions.CREATE,
  name: 'Stock',
  singularName: 'Stock',
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const companyCommons = useSelector((state: RootState) => state.companyCommons)
  const dispatch = useDispatch();
  const [ module, setModule ] = useState<IModule>(m)
  const navigate = useNavigate();

  const [ departmentModule, setDepartmentModule ] = useState<ICompanyModule>()

  const [ subdepartmentsOrigin, setSubdepartmentsOrigin ] = useState<ISubdepartment[]>([])
  const [ subdepartmentsDest, setSubdepartmentsDest ] = useState<ISubdepartment[]>([])

  const [ departmentOrigin, setDepartmentOrigin ] = useState<IDepartment>()
  const [ subdepartmentOrigin, setSubdepartmentOrigin ] = useState<IDepartment>()

  const [ departmentDest, setDepartmentDest ] = useState<IDepartment>()
  const [ subdepartmentDest, setSubdepartmentDest ] = useState<IDepartment>()

  const loadModules = () => {
    const department = loadModuleInformation(selectedCompany?.company, EModules.COMPANY_DEPARTMENTS)
    if (department) {
      setDepartmentModule(department)
    }
  }

  const goBack = () => {
    if (!selectedCompany.company) return
    navigate(ERoutes.COMPANY_STOCK_VIEW.replaceAll(":cid", selectedCompany.company.id))
  }

  const getDepartments = async () => {
    if (!selectedCompany.company) return;

    const response = await findCompanyDepartmentsRequest(
      dispatch,
      {
        page: 1,
        perPage: 100,
        query: '',
      },
      selectedCompany.company,
    )

    if (response?.data) {
      dispatch(setDepartments(response.data.data))
    }
  }

  useEffect(() => {
    loadModules()
    getDepartments()
  }, [])

  const getSubdepartments = async (id: string, origin: boolean) => {
    if (!selectedCompany.company) return

    const response = await findCompanySubdepartmentsRequest(
      dispatch,
      selectedCompany.company,
      id,
    )

    if (response.data) {
      if (origin) {
        setSubdepartmentsOrigin(response.data.data)
      } else {
        setSubdepartmentsDest(response.data.data)
      }
    }
  }

  const selectDepartmentOrigin = (id: string) => {
    const d = companyCommons.departments.find((dt) => dt.id === id)
    if (!d) {
      setDepartmentOrigin(undefined)
      return
    }

    getSubdepartments(d.id || '', true)

    setDepartmentOrigin(d)
  }

  const selectSubdepartmentOrigin = (id: string) => {
    const d = subdepartmentsOrigin.find((dt) => dt.id === id)
    if (!d) {
      setSubdepartmentOrigin(undefined)
      return
    }

    setSubdepartmentOrigin(d)
  }

  const selectDepartmentDest = (id: string) => {
    const d = companyCommons.departments.find((dt) => dt.id === id)
    if (!d) {
      setDepartmentDest(undefined)
      return
    }

    getSubdepartments(d.id || '', false)

    setDepartmentDest(d)
  }

  const selectSubdepartmentDest = (id: string) => {
    const d = subdepartmentsOrigin.find((dt) => dt.id === id)
    if (!d) {
      setSubdepartmentDest(undefined)
      return
    }

    setSubdepartmentDest(d)
  }

  const create = async () => {
    const alerts = new RCAlerts(module)

    if (!departmentOrigin) {
      alerts.alert(
        'error',
        `Debes seleccionar un ${transformToSingular(departmentModule?.settings.private.moduleName || '')} de origen`,
      )
      return
    }

    if (!subdepartmentOrigin) {
      alerts.alert(
        'error',
        `Debes seleccionar un ${transformToSingular(departmentModule?.settings.private.subdepartmentName || '')} de origen`,
      )
      return
    }

    if (!departmentDest) {
      alerts.alert(
        'error',
        `Debes seleccionar un ${transformToSingular(departmentModule?.settings.private.moduleName || '')} de destino`,
      )
      return
    }

    if (departmentOrigin.id === departmentDest.id && subdepartmentOrigin.id === subdepartmentDest?.id) {
      alerts.alert(
        'error',
        `La transferencia debe tener distinto destino.`,
      )
      return
    }

    alerts.preCreate({
        async onAccept() {
          if (!selectedCompany.company) return
          const response = await createCompanyStockTransferRequest(
            dispatch,
            {
              from: {
                departmentId: departmentOrigin.id || '',
                subDepartmentId: subdepartmentOrigin.id || '',
              },
              to: {
                departmentId: departmentDest.id || '',
                subDepartmentId: subdepartmentDest?.id,
              },
            },
            selectedCompany.company,
          )

          alerts.created({
            response,
            successHttpCode: EHttpStatusCode.ACCEPTED,
            onAccept() {
              goBack()
            }
          })
        },
        onCancel() {
        }
      }, `Crearás una transferencia de stock ${departmentOrigin.id === departmentDest.id ? 'local' : 'interlocal'}.\n\nEsta acción no se puede deshacer.`,
    )
  }

  return <SecureView module={module} setModule={setModule}>
    <Card className={'offset-3 col-6'}>
      <CardBody title={'Crear transferencia'}>
        <Row className={'mt-4'}>
          <Col sm={12}>
            <h4>Origen</h4>
          </Col>

          <Col sm={12}>
            <label>{CapitalizeAndTransform(departmentModule?.settings.private.moduleName || '')}</label>
            <select className={'custom-select'} value={departmentOrigin?.id}
                    onChange={(e) => selectDepartmentOrigin(e.target.value)}
            >
              <option>Selecciona...</option>
              {companyCommons.departments.map((depto) => {
                return <option key={depto.id} value={depto.id}>{depto.name}</option>
              })
              }
            </select>
          </Col>

          <Col sm={12} className={'mt-3'}>
            <label>{CapitalizeAndTransform(departmentModule?.settings.private.subdepartmentName || '')}</label>
            <select disabled={!departmentOrigin} className={'custom-select'} value={subdepartmentOrigin?.id}
                    onChange={(e) => selectSubdepartmentOrigin(e.target.value)}>
              <option>Selecciona...</option>
              {subdepartmentsOrigin.map((st) => {
                return <option key={st.id} value={st.id}>{st.name}</option>
              })
              }
            </select>
          </Col>
        </Row>

        <Row className={'mt-4'}>
          <Col sm={12}>
            <h4>Destino</h4>
          </Col>

          <Col sm={12}>
            <label>{CapitalizeAndTransform(departmentModule?.settings.private.moduleName || '')}</label>
            <select className={'custom-select'} value={departmentDest?.id}
                    onChange={(e) => selectDepartmentDest(e.target.value)}
            >
              <option>Selecciona...</option>
              {companyCommons.departments.map((depto) => {
                return <option key={depto.id} value={depto.id}>{depto.name}</option>
              })
              }
            </select>
          </Col>

          <Col sm={12} className={'mt-3'}>
            <label>{CapitalizeAndTransform(departmentModule?.settings.private.subdepartmentName || '')}</label>
            <select disabled={!departmentDest} className={'custom-select'} value={subdepartmentDest?.id}
                    onChange={(e) => selectSubdepartmentDest(e.target.value)}>
              <option>Selecciona...</option>
              {subdepartmentsDest.map((st) => {
                return <option key={st.id} value={st.id}>{st.name}</option>
              })
              }
            </select>
          </Col>
        </Row>

        <Button variant="success" type="button" onClick={create} className={'mt-5'}>
          <i className='ti-save'></i> Crear
        </Button>

        <Button variant="secondary" type="button" onClick={goBack} className={'mt-5 ml-2'}>
          Cancelar
        </Button>
      </CardBody>
    </Card>
  </SecureView>
}
