import Body from "../../../shared/common/Body";
import {Alert} from "react-bootstrap";
import {Link, useLocation, useNavigate} from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes";
import {useDispatch} from "react-redux";
import accountActivationRequest from "../../../core/api/requests/auth/accountActivationRequest";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";
import {useEffect} from "react";

export default function AccountActivationVerify() {
  const dipatch = useDispatch();
  const navigator = useNavigate();
  const { search } = useLocation();

  const request = async () => {
    const response = await accountActivationRequest(
      dipatch,
      {
        token: new URLSearchParams(search).get('t') || '',
      }
    );

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `cuenta activada exitosamente`,
        'success',
      ).then(() => navigator(ERoutes.AUTH_LOGIN))
    } else {
      sweetalert2.fire(
        `Error`,
        response.data.message,
        'error',
      ).then(() => navigator(ERoutes.AUTH_LOGIN))
    }
  }

  useEffect(() => {
    request();
  }, [])

  return <div></div>
}
