import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import requestMiddlewares from "../../requestMiddlewares";

export interface ICreateCompanyRequest {
  name?: string;
  code?: string;
  owner?: string;
}

export default async function createCompanyRequest(
  dispatch: Dispatch,
  input: ICreateCompanyRequest,
): Promise<IResponse> {
  dispatch(startLoading());
  const request = new RACARequest('companies', 'create')
    .setData(input);

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
