export default {
  'warned': 'advertido',
  'received': 'recibido',
  'viewed': 'visto',
  'requested': 'requerido',
  'alerted': 'alertado',
  'delivered': 'entregado',
  'cancelled': 'cancelado',
  'piece': 'unidad',
}
