import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import requestMiddlewares from "../../requestMiddlewares";

export interface ICreateUserRequest {
  name?: string;
  lastName?: string;
  email?: string;
  roleId?: string;
}

export default async function createUserRequest(
  dispatch: Dispatch,
  input: ICreateUserRequest,
): Promise<IResponse> {
  dispatch(startLoading());
  const request = new RACARequest('users', 'create')
    .setData(input);

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
