import {useEffect, useState} from "react";
import {IAssignCompanyUserRequest} from "../../core/api/requests/company/company-users/assignCompanyUserRequest";
import {Button, Form, Modal} from "react-bootstrap";
import IRole from "../../core/interfaces/IRole";
import findCompanyRolesRequest from "../../core/api/requests/company/company-roles/findCompanyRolesRequest";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import IModule from "../../core/interfaces/IModule";
import {ICreateCompanyRoleInput} from "../../core/api/requests/company/company-roles/companyRoleCreateRequest";
import findPermissionsRequest from "../../core/api/requests/roles/findPermissionsRequest";
import IPermission from "../../core/interfaces/IPermission";
import t from "../../core/utils/t";
import findByIdCompanyRoleRequest from "../../core/api/requests/company/company-roles/findByIdCompanyRoleRequest";
import {IUpdateCompanyRoleInput} from "../../core/api/requests/company/company-roles/companyRoleUpdateRequest";

export interface ICompanyUpdateRoleModal {
  onSubmit(id: string, input: IUpdateCompanyRoleInput): void;
  onClose(): void;
  show: boolean;
  module: IModule;
  id: string;
}

export default function (data: ICompanyUpdateRoleModal) {
  const dispatch = useDispatch()
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const futils = useSelector((state: RootState) => state.frontendUtilities)

  const [ name, setName ] = useState<string>('')
  const [ dashboard, setDashboard ] = useState<string>('default')
  const [ permissions, setPermissions ] = useState<IPermission[]>([])
  const [ locked, setLocked ] = useState<boolean>(false)

  const [ permissionsList, setPermissionsList ] = useState<{module: string, permissions: IPermission[] }[]>([])

  const getRole = async () => {
    if (!selectedCompany.company) return

    const response = await findByIdCompanyRoleRequest(
      dispatch,
      selectedCompany.company,
      data.id,
    )

    if (response.data) {
      setName(response.data.name)
      setDashboard(response.data.dashboard)
      setPermissions(response.data.permissions)
      setLocked(response.data.locked)
    }
  }

  const getPermissions = async () => {
    if (!selectedCompany.company) return
    const response = await findPermissionsRequest(dispatch)

    if (response.data) {
      let permTemp: any = []
      response.data.forEach((p) => {
        const m = selectedCompany?.company?.modules?.find((m) => m.name === p.module)
        if (!m) return

        const index = permTemp.findIndex((tm: any) => tm.module === p.module)
        if (index === -1) {
          permTemp.push({
            module: p.module,
            permissions: [p],
          })
        } else {
          permTemp[index].permissions.push(p)
        }
      })

      setPermissionsList([
        ...permTemp
      ])
    }
  }

  useEffect(() => {
    if (data.show) {
      if (!data.id) {
        data.onClose()
      }

      getPermissions()
      getRole()
    }
    return () => {
    }
  }, [data.show])

  const send = () => {
    if (!name) return

    data.onSubmit(data.id,{
      name,
      permissions,
      dashboard,
    })

    data.onClose()
  }

  const toggleSelectPermission = (p: IPermission) => {
    const exists = permissions.findIndex((pt) => pt.module === p.module && pt.method === p.method)

    if (exists > -1) {
      let tempPerm = permissions

      tempPerm = tempPerm.filter((pt) => {
        return !(p.module === pt.module && p.method === pt.method)
      })

      setPermissions([
        ...tempPerm
      ])
    } else {
      setPermissions((prevState) => {
        return [
          ...prevState,
          {
            module: p.module,
            method: p.method,
          }
        ]
      })
    }
  }

  return <Modal size={'xl'} show={data.show} onHide={data.onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Modificar rol funcional {locked ? ' - (Bloqueado)' : ''}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>ID</Form.Label>
          <Form.Control
            autoFocus
            value={data.id}
            disabled
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="cliente"
            autoFocus
            disabled={locked}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Dashboard</Form.Label>
          <select disabled={locked} className={'custom-select'} value={dashboard} onChange={(e) => setDashboard(e.target.value)}>
            <option>Selecciona...</option>
            {futils?.futils?.companies?.dashboards?.map(r => {
              return <option key={r.dashboard} value={r.dashboard}>{t(r.dashboard)}</option>
            })
            }
          </select>
        </Form.Group>
      </Form>

      <div className={'col-12'}>
        <div className={'permissions-container row'}>
          {permissionsList.map((p) => {
            return <div key={p.module} className={'col-4 mt-2'}>
                <h6 style={{fontWeight: 'bold'}}>{t(p.module || '')}</h6>
                <div>
                  {p?.permissions?.map((pt) => {
                    const checked = permissions.findIndex((pp) => pp.module === pt.module && pp.method === pt.method)

                    return <Form.Check // prettier-ignore
                      type={'checkbox'}
                      disabled={locked}
                      onChange={() => toggleSelectPermission(pt)}
                      id={`${pt.module}${pt.method}`}
                      checked={checked > -1}
                      label={t(pt.method || '')}
                    />
                  })
                  }
                </div>
            </div>
          })
          }
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cancelar
      </Button>
      <Button
        variant="success"
        onClick={send}
      >
        Modificar
      </Button>
    </Modal.Footer>
  </Modal>

}
