import SecureView from '../../shared/common/SecureView';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import {Button, Col, Form, Row} from "react-bootstrap";
import ECompanyDashboards from "../../core/enums/ECompanyDashboards";
import DashboardOrders from "./types/DashboardOrders";
import IModule from "../../core/interfaces/IModule";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import {useState} from "react";

const m: IModule = {
  name: 'Dashboard',
  singularName: 'Dashboard',
}

export default function Dashboard() {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany);
  const u = useSelector((state: RootState) => state.userMe);
  const [ module, setModule ] = useState<IModule>(m)

  if (!selectedCompany.company) return <SecureView><div></div></SecureView>
  return <SecureView module={module} setModule={setModule}>
    <Card className={'col-12'}>
      <CardBody>
        <Row>
          <Col xs={6}>
            <h2>{selectedCompany.company.name} {selectedCompany.company.code}</h2>
          </Col>
        </Row>

        {u?.user?.role?.dashboard === ECompanyDashboards.ORDER_MAKER &&
          <DashboardOrders />
        }
      </CardBody>
    </Card>
  </SecureView>
}
