import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';

export interface IScanner {
  showModal: boolean;
  sessionId?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  code?: string;
  time?: Date;
}

const initialState: IScanner = {
  showModal: false,
};

const scannerSlice = createSlice({
  name: EStores.COMPANY_COMMONS,
  initialState,
  reducers: {
    setScanner(state: IScanner, payload: PayloadAction<IScanner>) {
       return {
         ...state,
         ...payload.payload,
       }
    },
  },
});

export const {
  setScanner,
} = scannerSlice.actions;

export default scannerSlice.reducer;
