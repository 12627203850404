import IScanner from "../../core/interfaces/IScanner";
import {Badge, Button} from "react-bootstrap";
import moment from "moment/moment";
import {useEffect, useState} from "react";
import IScannerSession from "../../core/interfaces/IScannerSession";
import IWebsocketRequest from "../../core/interfaces/IWebsocketRequest";
import EWebsocketRequestActions from "../../core/enums/EWebsocketRequestActions";
import getMyCompanyProfileRequest from "../../core/api/requests/me/getMyCompanyProfileRequest";
import createCompanyScannerSessionRequest
  from "../../core/api/requests/company/company-scanner/createCompanyScannerSessionRequest";
import EHttpStatusCode from "../../core/enums/EHttpStatusCodes";
import {setScanner} from "../../core/store/slices/scannerSlice";
import {getSession} from "../../core/utils/sessionStorage";
import configuration from "../../configuration";
import EWebsocketActions from "../../core/enums/EWebsocketActions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {useParams} from "react-router-dom";

let webSocket: WebSocket;

export default function (): IScanner {
  const dispatch = useDispatch();
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const params = useParams()

  // Scanner
  const [ connected, setConnected ] = useState(false);
  const [ linked, setLinked ] = useState(false);
  const [ companyCode, setCompanyCode ] = useState('');
  const [ lastConnectionCheck, setLastConnectionCheck ] = useState<Date | null>(new Date());
  const [ session, setSession ] = useState<IScannerSession>()
  const [ code, setCode ] = useState<string>('')

  useEffect(() => {
    makeLink()
  }, [connected]);

  const establishReceiveConnection = (session: IScannerSession) => {
    const message: IWebsocketRequest = {
      action: EWebsocketRequestActions.COMPANY_SCANNER_RECEIVE,
      body: {
        id: session.id,
      }
    }

    webSocket?.send(JSON.stringify(message))
  }

  const openScanner = async () => {
    let company: any = selectedCompany.company
    if (!company && params.cid) {
      company = await getMyCompanyProfileRequest(dispatch, params.cid);
    }

    if (!company) {
      return;
    }

    const session = await createCompanyScannerSessionRequest(
      dispatch,
      {},
      company,
    )

    if (session.data && session.statusCode === EHttpStatusCode.ACCEPTED) {
      setSession(session.data)
      connect()
    }
  }

  const disconnect = () => {
    if (webSocket) {
      webSocket.close();
    }

    setConnected(false)
    setLinked(false)
    setSession(undefined)
  }

  const seeQR = () => {
    if (!session || !connected || !selectedCompany.company) return

    const url = `${window.location.origin}/scanner?t=${session.token}&cid=${selectedCompany.company.id}`
    dispatch(setScanner({
      showModal: true,
      sessionId: url
    }))

    if (url.includes('localhost')) {
      window.open(url)
    }
  }

  const makeLink = () => {
    if (!session || linked) return
    establishReceiveConnection(session)
  }

  const connect = function (): void {
    if (!selectedCompany.company) return

    const session = getSession()
    if (!session) return

    const url = `${configuration.apiRoute.ws}?token=${session.token}&companyId=${selectedCompany.company.id || ''}`;

    const ws = new WebSocket(url);

    ws.onmessage = ((event) => {
      const msg = JSON.parse(event.data);

      switch (msg?.action) {
        case EWebsocketActions.AUTHENTICATED: {
          setConnected(true);
          setCompanyCode(msg?.data.companyCode);

          webSocket = ws
          break;
        }
        case EWebsocketActions.CONNECTION_CHECK: {
          setLastConnectionCheck(new Date())
          break;
        }
        case EWebsocketActions.COMPANY_SCANNER_RECEIVER_ESTABLISHED: {
          if (msg.data?.id) {
            setLinked(true)
          }

          break;
        }
        case EWebsocketActions.COMPANY_SCANNER_NEW_SCAN: {
          const nc = msg.data?.value
          if (nc) {
            setCode(nc)
          } else {
            console.log(msg.data)
          }
          break;
        }
        case EWebsocketActions.ERROR: {
          alert(msg.data.message)
          break;
        }
        default:
          console.log(msg);
      }

    });

    let interval: NodeJS.Timeout;

    ws.onopen = (event) => {
      const message: IWebsocketRequest = {
        action: EWebsocketRequestActions.CHECK_CONNECTION,
        body: {}
      }

      interval = setInterval(() => {
        ws.send(JSON.stringify(message))
      }, 30000)
    }

    ws.onclose = (event) => {
      setConnected(false)
      if (interval) {
        clearInterval(interval)
      }
    }

    ws.onerror = (event) => {
      setConnected(false)
    }
  }

  return {
    linked,
    connected,
    lastConnectionCheck,
    session,
    code,
    seeQR,
    makeLink,
    openScanner,
  }
}
