import {Dispatch} from "redux";
import IBasicFilters from "../../../../interfaces/IBasicFilters";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";
import IReducedCompany from "../../../../interfaces/reduced/IReducedCompany";
import IStockProduct from "../../../../interfaces/IStockProduct";

export default async function (
  dispatch: Dispatch,
  id: string,
  filters: IBasicFilters,
  company: IReducedCompany,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('companyStock', 'listUnits')
    .setPathValues('COMPANY_ID', company.id)
    .setPathValues('PID', id)
    .setQueryParameters(filters)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
