enum EWebsocketActions {
  AUTHENTICATED = 'authenticated',
  CONNECTION_CHECK = 'connectionCheck',
  ERROR = 'error',
  COMPANY_ORDER_CREATED = 'company.order.created',
  COMPANY_ORDER_UPDATED = 'company.order.updated',
  COMPANY_ORDER_GET_ALL = 'company.orders.getAll',
  COMPANY_ORDER_PRODUCT_STATUS = 'company.orders.productStatus',

  COMPANY_SCANNER_RECEIVER_ESTABLISHED = 'company.scanner.receiver.established',
  COMPANY_SCANNER_NEW_SCAN = 'company.scanner.newScan',
}

export default EWebsocketActions;
