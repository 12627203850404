enum EModules {
  USERS = 'users',
  ROLES = 'roles',
  PERMISSIONS = 'permissions',
  SESSIONS = 'sessions',
  COMPANIES = 'companies',

  COMPANY_USERS = 'company.users',
  COMPANY_ROLES = 'company.roles',
  COMPANY_DEPARTMENTS = 'company.departments',
  COMPANY_PRODUCTS = 'company.products',
  COMPANY_ORDERS = 'company.orders',
  COMPANY_DEVICES = 'company.devices',
  COMPANY_REPORTS = 'company.reports',
  COMPANY_STOCK = 'company.stock',
}

export default EModules;
