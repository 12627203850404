import SecureView from "../../../shared/common/SecureView";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import findCompanyUsersRequest from "../../../core/api/requests/company/company-users/findCompanyUsersRequest";
import {Button, Form, InputGroup} from "react-bootstrap";
import hasPermission from "../../../core/utils/hasPermission";
import Paginator from "../../../shared/common/Paginator";
import ICompanyUser from "../../../core/interfaces/ICompanyUser";
import sweetalert2 from "sweetalert2";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import EHttpStatusCode from "../../../core/enums/EHttpStatusCodes";
import companyUserRemoveRequest from "../../../core/api/requests/company/company-users/companyUserRemoveRequest";
import CompanyCreateUserModal from "../../../shared/modals/CompanyCreateUserModal";
import assignCompanyUserRequest, {
  IAssignCompanyUserRequest
} from "../../../core/api/requests/company/company-users/assignCompanyUserRequest";
import companyUserUpdateRoleRequest, {
  ICompanyUserUpdateRoleRequest
} from "../../../core/api/requests/company/company-users/companyUserUpdateRoleRequest";
import IModule from "../../../core/interfaces/IModule";
import RCAlerts from "../../../core/utils/swal/RCAlerts";

const m: IModule = {
  module: EModules.COMPANY_USERS,
  method: ECommonPermissions.FIND,
  name: 'Usuarios',
  singularName: 'Usuario',
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const [ module, setModule ] = useState<IModule>(m)

  const [ users, setUsers ] = useState<ICompanyUser[]>([]);

  const [ count, setCount ] = useState<number>(0);

  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');

  // modal
  const [ show, setShow ] = useState<boolean>(false);

  const [ idToEdit, setIdToEdit ] = useState<string>('');
  const [ emailToEdit, setEmailToEdit ] = useState<string>('');
  const [ roleIdToEdit, setRoleIdToEdit ] = useState<string>('');

  const changePageAndFind = (page: number) => find(page)

  const find = async (
    pageByParam?: number,
  ) => {
    if (!selectedCompany.company) return;

    if (pageByParam) setPage(pageByParam)

    const response = await findCompanyUsersRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
      selectedCompany.company,
    )

    if (response?.data) {
      setUsers(response.data?.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)
    }
  }

  useEffect(() => {
    find()
  }, [selectedCompany.company])

  useEffect(() => {
    setPage(1)
  }, [query])

  const remove = (user: ICompanyUser) => {
    if (!selectedCompany.company || !user) return;

    new RCAlerts(module).preDelete({
      onAccept() {
        removeUserConfirmed(user)
      },
      onCancel() {
      }
    })
  }

  const removeUserConfirmed = async (user: ICompanyUser) => {
    if (!user || !selectedCompany.company) return;

    const response = await companyUserRemoveRequest(
      dispatch,
      {
        account: user.account,
      },
      selectedCompany.company,
    )

    new RCAlerts(module).delete({
      response,
      successHttpCode: EHttpStatusCode.OK,
      onAccept() {
        find(1)
      }
    })
  }

  const updateUserModal = (user: ICompanyUser) => {
    setIdToEdit(user.id)
    setEmailToEdit(user.account)
    setRoleIdToEdit(user.role.id);

    setShow(true);
  }

  const updateUser = async (data: ICompanyUserUpdateRoleRequest) => {
    if (!selectedCompany.company) return;

    const response = await companyUserUpdateRoleRequest(
      dispatch,
      data,
      idToEdit,
      selectedCompany.company,
    );

    new RCAlerts(module).update({
      response,
      successHttpCode: EHttpStatusCode.ACCEPTED,
      onAccept() {
        find(1)
      }
    })
  }

  const assignUser = async (data: IAssignCompanyUserRequest) => {
    if (!selectedCompany.company) return;

    const response = await assignCompanyUserRequest(
      dispatch,
      data,
      selectedCompany.company,
    );

    new RCAlerts(module).created({
      response,
      successHttpCode: EHttpStatusCode.ACCEPTED,
      onAccept() {
        find(1)
      }
    })
  }

  const handleClose = () => {
    setIdToEdit('')
    setEmailToEdit('')
    setRoleIdToEdit('')

    setShow(false)
  };
  const handleOpen = () => setShow(true);

  return <SecureView module={module} setModule={setModule}>
    <Card className={'col-12'}>
      <CardBody title={`${module.name} (${count})`}>
        <CompanyCreateUserModal
          onSubmit={assignUser}
          onSubmitEdit={updateUser}
          onClose={handleClose}
          id={idToEdit}
          roleId={roleIdToEdit}
          email={emailToEdit}
          show={show}
          module={module}
        />

        <div className={'row mt-5'}>
          <div className={'col-9'}>
            <InputGroup>
              <Form.Control
                placeholder="Buscar por nombre..."
                aria-label="Buscar por nombre..."
                aria-describedby="Buscar por nombre..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(key) => key.key === 'Enter' && find(1)}
              />
              <Button
                variant="outline-primary"
                id="button-addon2"
                onClick={() => find(1)}
              >
                <i className={'ti-search'}></i> Buscar
              </Button>
            </InputGroup>
          </div>

          <div className={'col-3'}>
            {hasPermission(permissions, { module: module.module, method: ECommonPermissions.CREATE }) &&
              <Button
                type={'button'}
                onClick={handleOpen}
                className="btn btn-info float-right"
              >Nuevo {module.singularName}
              </Button>
            }
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered m-t-30 table-hover contact-list">
            <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Rol</th>
              <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            {users.map((user) => {
              return <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.account}</td>
                <td>{user.role.name}</td>
                <td>
                  {hasPermission(permissions, { module: module.module, method: ECommonPermissions.REMOVE }) &&
                      <Button size={'sm'} variant={'danger'} onClick={() => remove(user)}>
                          <i className={'ti-trash'}></i> Desvincular
                      </Button>
                  }
                  {hasPermission(permissions, { module: module.module, method: ECommonPermissions.PARTIAL_UPDATE }) &&
                      <Button size={'sm'} className={'ml-2'} variant={'secondary'} onClick={() => updateUserModal(user)}>
                          <i className={'ti-pencil'}></i> Editar
                      </Button>
                  }
                </td>
              </tr>
            })
            }
            </tbody>
          </table>

          <Paginator
            total={totalPages}
            page={page}
            goTo={changePageAndFind}
          />
        </div>
      </CardBody>
    </Card>
  </SecureView>
}
